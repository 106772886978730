import React, { useEffect, useRef, useState } from 'react';



const MarcatoreQuotaTabella = (props) => {
    const { quota } = props;
    const [stile, setStile] = useState('bg-transparent');
    const [stileFreccia, setStileFreccia] = useState('');
    const prevQuota = usePrevious({quota});

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {
        if(prevQuota && prevQuota.quota !== quota && quota > 1) {
            if (prevQuota.quota>quota) {
                setStileFreccia('bi bi-arrow-down text-danger');
                setStile('bg-info text-white');
            } else {
                setStileFreccia('bi bi-arrow-up text-success');
                setStile('bg-info text-white');
            }           
            setTimeout(() => {
                setStile('quota-flash'); 
            }, 1000);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quota]);
  
    return (
        <>  
            <div className={"position-relative " + stile}>
                <i className={"position-absolute " + stileFreccia} style={{left: "5px", top: "-1px"}}></i>
                {quota > 1 ? parseFloat(quota).toFixed(2) : '-'}
            </div>
        </>
    );
}

export default MarcatoreQuotaTabella;