import { put, takeEvery, all, fork, select } from 'redux-saga/effects'
import { IppicaActionsType } from './constants';
import * as IppicaAction from './actions';
import { serverConst } from '../../constants/ippica'


const token_state = state=> state.Ippica.token;
const quote_state = state => state.Ippica.quote;

function* fetchConcessionari(): any {
  const token = yield select(token_state);
  const requestOption = {
    method: 'POST',
    body: JSON.stringify({'token': token}),
    headers: {
        'Accept': 'application/json',
    },
  }  
  const json_respone = yield fetch(serverConst.END_POINT_IPPICA + serverConst.END_POINT_GET_CONCESSIONARI, requestOption).then(response => response.json(), );
  yield put(IppicaAction.addConcessionario(json_respone));
}


function* concessionariWatcher(): any {
  yield takeEvery(IppicaActionsType.GET_CONCESSIONARI, fetchConcessionari)
}


function* fetchEventi(): any {
  const token = yield select(token_state);
  const requestOption = {
    method: 'POST',
    body: JSON.stringify({'token': token}),
    headers: {
        'Accept': 'application/json',
    },
  }
  const json_respone = yield fetch(serverConst.END_POINT_IPPICA + serverConst.END_POINT_GET_EVENTI,requestOption).then(response => response.json(), );
  yield put(IppicaAction.addEventi(json_respone));
}


function* fetchEvento(params): any {
  const token = yield select(token_state);
  const requestOption = {
    method: 'POST',
    body: JSON.stringify({'token': token, 'evento_id': params.payload.evento_id}),
    headers: {
        'Accept': 'application/json',
    },
  }
  const json_respone = yield fetch(serverConst.END_POINT_IPPICA + serverConst.END_POINT_GET_EVENTO, requestOption).then(response => response.json(), );
  yield put(IppicaAction.addEvento(json_respone, params.payload.evento_id, params.payload.quota_id, params.payload.concessionario_codice, params.payload.valore));
}


function* eventiWatcher(): any {
  yield takeEvery(IppicaActionsType.GET_EVENTI, fetchEventi)
  yield takeEvery(IppicaActionsType.GET_EVENTO, fetchEvento)
}

function* fetchQuote(params): any{
  const token = yield select(token_state);
  var errore_quote = false;
  const requestOption = {
      method: 'POST',
      body: JSON.stringify({'token':token, 'evento_id': params.payload.evento_id, 'concessionari': params.payload.concessionari, 'specialita': params.payload.specialita}),
      headers: {
          'Accept': 'application/json',
      },
  }
  const json_respone = yield fetch(serverConst.END_POINT_IPPICA + serverConst.END_POINT_GET_QUOTE_FOREVENTO, requestOption)
      .then(response => {
        if(response.status !== 200){
          errore_quote = true
          return []
        }
        return response.json()
      })
      .catch(error => {
        errore_quote = true
        return []
    });
  yield put(IppicaAction.setQuoteEvento(json_respone, params.payload.evento_id, errore_quote))
}

// fecth che recupera la quota_descrizione di un dato competitor se essa non è presente
function* fetchQuotaDescrizione(params): any{
  const quote = yield select(quote_state);
  if(params.payload.evento_id in quote 
    && 
      (
        !(params.payload.quota_id in quote[params.payload.evento_id]) || 
        !(params.payload.concessionario_codice in quote[params.payload.evento_id][params.payload.quota_id]) ||
        !('quota_descrizione' in quote[params.payload.evento_id][params.payload.quota_id][params.payload.concessionario_codice]) ||
        quote[params.payload.evento_id][params.payload.quota_id][params.payload.concessionario_codice]['quota_descrizione'] === ''
      )
    )
  {
    const token = yield select(token_state);
    const requestOption = {
        method: 'POST',
        body: JSON.stringify({'token':token, 'evento_id': params.payload.evento_id, 'concessionario': params.payload.concessionario_codice, 'quota_id': params.payload.quota_id}),
        headers: {
            'Accept': 'application/json',
        },
    }
    const json_respone = yield fetch(serverConst.END_POINT_IPPICA + serverConst.END_POINT_GET_QUOTA_DESCRIZIONE, requestOption)
    .then(response => {
      if(response.status != 200){
        return {};
      }
      return response.json()
    })
    .catch(error => {
      return {};
    });
    // in caso di errore la richiesta mi ritorna un json vuoto
    yield put(IppicaAction.setQuotaDescrizione(params.payload.evento_id,  params.payload.quota_id,  params.payload.concessionario_codice, json_respone))
  }
}
  


function* quoteWatchers(): any{
  yield takeEvery(IppicaActionsType.GET_QUOTE_EVENTO, fetchQuote);
  yield takeEvery(IppicaActionsType.SET_QUOTA, fetchQuotaDescrizione);
}

function* fetchSpecialita() {
  const token = yield select(token_state);
  const requestOption = {
    method: 'POST',
    body: JSON.stringify({'token': token}),
    headers: {
        'Accept': 'application/json',
    },
  }
  const json_respone = yield fetch(serverConst.END_POINT_IPPICA + serverConst.END_POINT_GET_SPECIALITA,requestOption).then(response => response.json(), );
  yield put(IppicaAction.addSpecialita(json_respone));
}


function* specialitaWatcher() {
  yield takeEvery(IppicaActionsType.GET_SPECIALITA, fetchSpecialita)
}


function* IppicaSaga(): any {
    yield all([
        fork(concessionariWatcher),
        fork(eventiWatcher),
        fork(quoteWatchers),
        fork(specialitaWatcher)
    ]);
}

  
export default IppicaSaga;
