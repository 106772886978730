export const IppicaActionsType = {
    ADD_CONCESSIONARI: '@@ippica/ADD_CONCESSIONARI',
    GET_CONCESSIONARI: '@@ippica/GET_CONCESSIONARI',
    CHANGE_VISUALIZATION_CONCESSIONARIO:'@@ippica/CHANGE_VISUALIZATION_CONCESSIONARIO',
    CHANGE_VISUALIZATION_SPECIALITA: '@@ippica/CHANGE_VISUALIZATION_SPECIALITA',
    ADD_EVENTI: '@@ippica/ADD_EVENTI',
    ADD_EVENTO: '@@ippica/ADD_EVENTO',
    GET_EVENTI: '@@ippica/GET_EVENTI',
    GET_EVENTO: '@@ippica/GET_EVENTO',
    ADD_QUOTE: '@@ippica/ADD_QUOTE',
    GET_QUOTE_EVENTO: '@@ippica/GET_QUOTE_EVENTO',
    SET_QUOTA: '@@ippica/SET_QUOTA',
    LOAD_QUOTE: '@@ippica/LOAD_QUOTE',
    SET_QUOTA_EVENTO: '@@ippica/SET_QUOTA_EVENTO',
    ADD_SPECIALITA: '@@ippica/ADD_SPECIALITA',
    GET_SPECIALITA: '@@ippica/GET_SPECIALITA',
    SET_TOKEN: '@@ippica/SET_TOKEN',
    SET_CAMPIONATI: '@@ippica/SET_CAMPIONATI',
    CHANGE_VISUALIZATION_EVENTI: '@@ippica/CHANGE_VISUALIZATION_EVENTI',
    SET_DISTANCE: '@@ippica/SET_DISTANCE',
    CHECK_ALERT_DISTANCE: '@@ippica/CHECK_ALERT_DISTANCE',
    SET_PERCENTUALE: '@@ippica/SET_PERCENTUALE',
    CHECK_ALERT_AVG_PERCENTUALE: '@@ippica/CHECK_ALERT_AVG_PERCENTUALE',
    CHECK_EVENT_ALERT_AVG_PERCENTUALE: '@@ippica/CHECK_EVENT_ALERT_AVG_PERCENTUALE',
    //CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE: '@@ippica/CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE',
    CHANGE_STATE_ALERT_SOUND: '@@ippica/CHANGE_STATE_ALERT_SOUND',
    DELETE_STARTED_EVENT: '@@ippica/DELETE_STARTED_EVENT',
    SET_ENABLED_STATE_EVENT: '@@ippica/CHANGE_ENABLED_STATE_EVENT',
    SET_ENABLED_STATE_COMPETITOR: '@@ippica/SET_ENABLED_STATE_COMPETITOR',
    SET_EYE_STATE: '@@ippica/SET_EYE_STATE',
    SET_ALERT_ABILITATI: '@@ippica/SET_ALERT_ABILITATI',
    SET_FILTRO_TIPO_DATA: '@@ippica/SET_FILTRO_TIPO_DATA',
    SET_FILTRO_EVENTO_NAME: '@@ippica/SET_FILTRO_EVENTO_NAME',
    SET_REF_LIST: '@@ippica/SET_REF_LIST',
    SET_OPEN_STATE_COMPETITOR: '@@ippica/SET_OPEN_STATE_COMPETITOR',
    ALL_SET_OPEN_STATE_COMPETITOR: '@@ippica/ALL_SET_OPEN_STATE_COMPETITOR',
    SET_OPEN_STATE_EVENTO: '@@ippica/SET_OPEN_STATE_EVENTO',
    ALL_SET_OPEN_STATE_EVENTO: '@@ippica/ALL_SET_OPEN_STATE_EVENTO',
    UPDATE_LIST: '@@ippica/UPDATE_LIST',
    RESIZE_LIST: '@@ippica/RESIZE_LIST',
    SAVE_IMPOSTAZIONI: '@@ippica/SAVE_IMPOSTAZIONI',
    SET_FILTRO_COMPETITOR: '@@ippica/SET_FILTRO_COMPETITOR',
    CHANGE_VISUALIZATION_ALL_EVENTI: '@@ippica/CHANGE_VISUALIZATION_ALL_EVENTI',
    CHANGE_TIPO_ORDINAMENTO: '@@ippica/CHANGE_TIPO_ORDINAMENTO',
    SET_SPECIALITA_EVENTO: '@@ippica/SET_SPECIALITA_EVENTO',
    SET_SPECIALITA_CAMPIONATO: '@@ippica/SET_SPECIALITA_CAMPIONATO',
    SET_VISUALIZZA_QUOTE_PRESENTI: '@@ippica/SET_VISUALIZZA_QUOTE_PRESENTI',
    SET_QUOTA_DESCRIZIONE: '@@ippica/SET_QUOTA_DESCRIZIONE',
    SET_DISTANCE_PUNTA: '@@ippica/SET_DISTANCE_PUNTA',
    CHECK_ALERT_DISTANCE_PUNTA: '@@ippica/CHECK_ALERT_DISTANCE_PUNTA',
};
