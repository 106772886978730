import React from 'react';
import ReactDOM from 'react-dom/client';
import routing from './Routes';
import store from './redux/store'
import {Provider} from 'react-redux';



const root = ReactDOM.createRoot(document.getElementById('root'));

//fare qui il controllo del token e se impostato correttamente renderizzare al routing altrimenti far apparire una pagina di erroe

root.render(
    <Provider store={store}>
        {routing}
    </Provider> 
);



