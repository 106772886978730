import { combineReducers } from 'redux';

import Marcatori from './marcatori/reducers';
import Ippica from './ippica/reducers';

export default (combineReducers({
    Marcatori,
    Ippica
    
}))
