import React from 'react';



const CompetitorQuotaBF = (props) => {
    const quota = props.quota;
    const quota_2 = props.quota_2; 
    
  
    return (
        <>  
            <div className={"position-relative "} style={{float: "left", width: "47px", backgroundColor: "#a6d8ff"}}>
                <i className={"position-absolute "}></i>
                {quota_2 > 1 ? parseFloat(quota_2).toFixed(2) : '-'}
            </div>
            <div className={"position-relative "} style={{float: "right", width: "47px", background: "#f694aa"}}>
                <i className={"position-absolute "}></i>
                {quota > 1 ? parseFloat(quota).toFixed(2) : '-'}
            </div>
        </>
    );
}

export default CompetitorQuotaBF;