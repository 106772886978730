import { checkEventoAlert, checkQuotaAlertBF, checkQuotaMediaFunc } from "../../utils/marcatori/alert";
import { MarcatoriActionsType } from "./constants"
import { MarcatoriConst} from "../../constants"
import {getMaxOdds, getConcessionariNonVisualizzati, cleanQuote, filterCheckEvento, getLocalState, saveLocalState, inizializzaEventoCampi, campionato_generaelenco, waitTimeCancellazioneEventi, getConcessionariNotQuotaMedia} from "../../utils/marcatori/odds_function"
import alert_sound from '../../assets/sounds/avviso.mp3'

const local_state = getLocalState()

const INIT_STATE = {
  concessionari: [],
  eventi: [],
  quote: {},
  specialita: [],
  token: '',
  campionati: [],
  distance: local_state.impostazioni.distanza,
  percentuale_avg: local_state.impostazioni.percentuale,
  limite_quota_media: local_state.impostazioni.limite_quota_media,
  filtro_evento: '',
  filtro_data: MarcatoriConst.EVENTI_OGGI_DOMANI,
  filtro_marcatore: '',
  alert_attivi: local_state.impostazioni.alert_abilitati,//{'BF':{'abilitato': true, 'colore': 'bg-danger', 'descrizione': 'Alert BETFAIR'}, 'AVG': {'abilitato': true, 'colore': 'bg-warning', 'descrizione': 'Alert Quota Media'}},
  alert_eye: false,
  loading: true,
  alert_sound_enabled: true,
  ref_list: null,
  ultimo_aggiornamento: null,
  timeout_cancellazione_eventi: 1000000000
};

type MarcatoriAction = {
  type: string,
  payload: { actionType?: string, data?: any, error?: string },
};

type State = { 
  concessionari?: [] | null,
  eventi?: [] | null,
  quote?: {} | null,
  specialita?: [] | null,
  token?: '' | null,
  campionati?: [] | null,
  distance?: 0 | null,
  percentuale_avg?: 0.3 | null,
  loading?: Boolean | null,
  alert_sound_enabled?: Boolean | null,
  filtro_evento?: '' | null,
  filtro_data?: Object | null,
  filtro_marcatore?: '' | null,
  alert_attivi?: Object | null,
  alert_eye?: Boolean | null,
  ref_list?: Object | null,
  ultimo_aggiornamento?: Object | null,
  timeout_cancellazione_eventi?: 0 | null,
  limite_quota_media?: 1000 | null,
};

const suono_alert = new Audio(alert_sound)

const Marcatori = (state: State = INIT_STATE, action: MarcatoriAction): any => {
  // definizioni delle variabili usate all'interno dello switch [Sulla destra tutti i case dove sono usate]
  
  var tempConcessionari; // ADD_CONCESSIONARI, CHANGE_VISUALIZATION_CONCESSIONARIO
  var index; // CHANGE_VISUALIZATION_CONCESSIONARIO, CHANGE_VISUALIZATION_SPECIALITA, CHANGE_VISUALIZATION_EVENTI
  var eventi_scaricati; // ADD_EVENTI, ADD_EVENTO
  var tempEvento; // ADD_EVENTI, ADD_EVENTO
  var evento; // ADD_EVENTO
  var tempQuote; // SET_QUOTA_EVENTO, SET_QUOTA, CHECK_ALERT_DISTANCE, CHECK_ALERT_AVG_PERCENTUALE, CHECK_EVENT_ALERT_AVG_PERCENTUALE,CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE
  var quote; // SET_QUOTA_EVENTO
  var evento_id; // SET_QUOTA_EVENTO, SET_QUOTA, CHECK_EVENT_ALERT_AVG_PERCENTUALE, CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE
  var quota_id; // SET_QUOTA, CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE
  var concessionario_codice; // SET_QUOTA
  var concessionari_da_non_contare; // SET_QUOTA, CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE
  var tempSpecialita; // ADD_SPECIALITA, CHANGE_VISUALIZATION_SPECIALITA
  var tempCampionati; // SET_CAMPIONATI, CHANGE_VISUALIZATION_EVENTI
  var campionatiInseriti; // SET_CAMPIONATI
  var old_alert; // SET_QUOTA
  var tempEventi; // CHANGE_VISUALIZATION_EVENTI
  var index_marcatore; 
  var concessionari_da_non_considerare; // CHECK_ALERT_AVG_PERCENTUALE, CHECK_EVENT_ALERT_AVG_PERCENTUALE
  var currentTimestamp;
  var evento_rimosso;
  var tempVisible;
  var tempValutaQuotaMedia;
  var stato;
  var concessionari_non_visualizzati;

  switch (action.type) {
    case MarcatoriActionsType.ADD_CONCESSIONARI:
      tempConcessionari = []
      action.payload.forEach(concessionario => {
        // aggiungo i campi
        // visible che permette di visualizzare o meno il concessionario
        // valuta quota_quota_media che permette o meno di valutare la quota media anche se non è visualizzato
        tempVisible = true
        tempValutaQuotaMedia = true
        // avendo già controllato che la chiave esista posso controllare direttamente che esista la specialita
        if(concessionario.concessionario_codice in local_state.impostazioni.concessionari){
          tempVisible = local_state.impostazioni.concessionari[concessionario.concessionario_codice].visible
          tempValutaQuotaMedia = local_state.impostazioni.concessionari[concessionario.concessionario_codice].valuta_quota_media
        }
        tempConcessionari.push({...concessionario, visible: tempVisible, valuta_quota_media: tempValutaQuotaMedia})
      });
      // aggiungo un concessionario virtuale
      tempConcessionari.push({
        'concessionario_codice': MarcatoriConst.CONCESSIONARIO_VIRTUALE,
        'concessionario_descrizione': MarcatoriConst.CONCESSIONARIO_VIRTUALE,
        visible: false
      })

      return {
        ...state,
        concessionari: tempConcessionari,
        loading: state.loading && !(state.eventi.length > 0 && state.specialita.length > 0) // tengo conto anche del loading delle specialita e degli eventi quando carico i concessionari
                                                                              // così evitando che possa richiedere le quote per gli eventi senza passare concessionari e specialita
      };
    case MarcatoriActionsType.GET_CONCESSIONARI:
      return state
    case MarcatoriActionsType.CHANGE_VISUALIZATION_CONCESSIONARIO:
      tempConcessionari = [...state.concessionari]
      index = tempConcessionari.indexOf(action.payload)
      tempConcessionari[index].visible = !tempConcessionari[index].visible
      concessionari_da_non_contare = getConcessionariNotQuotaMedia(tempConcessionari)
      concessionari_non_visualizzati = getConcessionariNonVisualizzati(tempConcessionari)
      tempQuote = {...state.quote}
      Object.keys(tempQuote).map((evento_id)=>{
        Object.keys(tempQuote[evento_id]).map((quota_id)=>{
          tempQuote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['value'] = getMaxOdds(tempQuote[evento_id][quota_id], concessionari_non_visualizzati)
          tempQuote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] = checkQuotaAlertBF(evento_id, quota_id, MarcatoriConst.CONCESSIONARIO_VIRTUALE, tempQuote, state.distance)  
          tempQuote[evento_id][quota_id] = checkQuotaMediaFunc(tempQuote[evento_id][quota_id], state.percentuale_avg, concessionari_da_non_contare, state.limite_quota_media, concessionari_non_visualizzati)

        })
      })
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0);
      stato = {
        ...state,
        concessionari: tempConcessionari,
        quote: tempQuote,
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      return {
        ...stato,
        eventi: tempEventi
      }
    case MarcatoriActionsType.ADD_EVENTI:
      eventi_scaricati = []
      tempEvento = {}
      if(action.payload.length > 0){
        action.payload.forEach(evento =>{
          tempEvento = inizializzaEventoCampi(evento)
          tempEvento = filterCheckEvento(state, tempEvento)
          eventi_scaricati.push(tempEvento)
        })
      }
      var new_timeout_cancellazione_eventi = waitTimeCancellazioneEventi(eventi_scaricati)
      return {
        ...state,
        eventi: eventi_scaricati,
        timeout_cancellazione_eventi: new_timeout_cancellazione_eventi,
        loading: state.loading && !(state.concessionari.length > 0 && state.specialita.length > 0) // tengo conto anche del loading delle specialita e dei concessionari quando carico gli eventi
                                                                                  // così evitando che possa richiedere le quote per gli eventi senza passare concessionari e specialita
      }
    case MarcatoriActionsType.ADD_QUOTE:
      return {
        ...state,
        quote: action.payload
      }
    case MarcatoriActionsType.GET_QUOTE_EVENTO:
      return state
    case MarcatoriActionsType.SET_QUOTA_EVENTO:
      tempQuote = {...state.quote}
      quote = action.payload.quote
      evento_id = action.payload.evento_id
      var quote_errore = action.payload.quote_errore

      tempEventi = [...state.eventi]
      index = tempEventi.findIndex(function(evento) {
        return evento.evento_id  === evento_id
      })
      if(index !== -1){
        tempEventi[index].quote_errore = quote_errore
      }
      if(!quote_errore){
        concessionari_da_non_considerare = getConcessionariNotQuotaMedia(state.concessionari)
        concessionari_non_visualizzati = getConcessionariNonVisualizzati(state.concessionari)
        if(quote && Object.keys(quote).length > 0)
        {
          Object.keys(quote).map((key)=>{
            evento_id = key
            Object.keys(quote[key]).map((quota_id) =>{
              quote[key][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE] = {} 
              quote[key][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['value'] = getMaxOdds(quote[key][quota_id], getConcessionariNonVisualizzati(state.concessionari))
            })
            
            tempQuote[key] = checkEventoAlert(key, quote, state.distance, state.percentuale_avg, concessionari_da_non_considerare, state.limite_quota_media, concessionari_non_visualizzati)
          })
        }
      }

      return {
        ...state,
        quote: tempQuote,
        ultimo_aggiornamento: Date.now()
      }
    case MarcatoriActionsType.ADD_EVENTO:
    case MarcatoriActionsType.SET_QUOTA:
      var temp_stato_evento = {...state}
      new_timeout_cancellazione_eventi = temp_stato_evento.timeout_cancellazione_eventi
      if(action.type === MarcatoriActionsType.ADD_EVENTO) {
        evento = action.payload.evento
        eventi_scaricati = [...state.eventi]
        tempEvento = {}
        if(evento.length > 0){

          evento.forEach(ev => {
            // ricerco se l'evento è già presente nella lista degli eventi
            var evento_presente = eventi_scaricati.find(function(evento_temp) {
              return evento_temp.evento_id  === ev.evento_id 
            })
            // nel caso fosse già presente non lo aggiungo alla lista degli eventi
            if(evento_presente === undefined){
              tempEvento = inizializzaEventoCampi(ev)
              tempEvento.marcatori.forEach(marc => {
                marc.visible = !state.alert_eye
              })
              tempEvento = filterCheckEvento(state, tempEvento)
              eventi_scaricati.push(tempEvento)
            }
          })
          new_timeout_cancellazione_eventi = waitTimeCancellazioneEventi(eventi_scaricati)
          // genero la lista dei campionati presenti ed rigenero la lista dei campionati nel caso in cui
          // i nuovi eventi appartegono a nuovi campionati
          campionatiInseriti = []
          state.campionati.forEach((campionato)=>{
            campionatiInseriti.push(campionato.campionato_id)
          })
          var campionati_aggiornati = campionato_generaelenco(eventi_scaricati, state.filtro_data, state.campionati, campionatiInseriti);

          temp_stato_evento = {
            ...state,
            eventi: eventi_scaricati,
            campionati: campionati_aggiornati
          }
        }
      }
      tempQuote = {...temp_stato_evento.quote}
      evento_id = action.payload.evento_id
      quota_id = action.payload.quota_id
      concessionario_codice = action.payload.concessionario_codice  
      concessionari_da_non_contare = getConcessionariNotQuotaMedia(temp_stato_evento.concessionari)
      concessionari_non_visualizzati = getConcessionariNonVisualizzati(temp_stato_evento.concessionari)
      if(!(evento_id in tempQuote)){
        tempQuote[evento_id] = {}
      }
      if(!(quota_id in tempQuote[evento_id])){
        tempQuote[evento_id][quota_id] = {}
      }
      tempEventi = [...temp_stato_evento.eventi]

      if((evento_id in tempQuote) && (quota_id in tempQuote[evento_id])){ 
        if(!(concessionario_codice in tempQuote[evento_id][quota_id])){
            tempQuote[evento_id][quota_id][concessionario_codice] = {}
        }
        if(!(MarcatoriConst.CONCESSIONARIO_VIRTUALE in tempQuote[evento_id][quota_id])){
          tempQuote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE] = {}
        }
        tempQuote[evento_id][quota_id][concessionario_codice]['value'] = action.payload.valore
        tempQuote[evento_id][quota_id][concessionario_codice]['value_2'] = action.payload.valore_2
        if(!('value' in tempQuote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE])){
          tempQuote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['value'] = 0
        }
        if(!MarcatoriConst.CONFRONTO.includes(concessionario_codice)){
          tempQuote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['value'] = getMaxOdds(tempQuote[evento_id][quota_id], getConcessionariNonVisualizzati(temp_stato_evento.concessionari))
        }
        // 0 indicher� nessun alert, 1 indicher� solo BF, 2 indicher� un alert rispetto alla quota media
        if(!('alert' in tempQuote[evento_id][quota_id][concessionario_codice])){
          tempQuote[evento_id][quota_id][concessionario_codice]['alert'] = 0 
        }
        if(!('alert' in tempQuote[evento_id][quota_id][concessionario_codice])){
          tempQuote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] = 0 
        }
        var souna_alert = false
        if(!MarcatoriConst.CONFRONTO.includes(concessionario_codice)) {
          old_alert = tempQuote[evento_id][quota_id][concessionario_codice]['alert']
          tempQuote[evento_id][quota_id][concessionario_codice]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_codice, tempQuote, temp_stato_evento.distance) 
          tempQuote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] = checkQuotaAlertBF(evento_id, quota_id, MarcatoriConst.CONCESSIONARIO_VIRTUALE, tempQuote, temp_stato_evento.distance) 
          // questa variabile indica se è scattato un alert relativo alla quota media
          // per passarlo come parametro passo un array con un solo elemento che è un boolean in questo modo le modifiche attuate all'array
          // sono visibili anche all'esterno
          let alert_attivato = [false]
          tempQuote[evento_id][quota_id] = checkQuotaMediaFunc(tempQuote[evento_id][quota_id], temp_stato_evento.percentuale_avg, concessionari_da_non_contare, state.limite_quota_media, concessionari_non_visualizzati, alert_attivato)
          souna_alert = Boolean(
                        (
                          (!old_alert & MarcatoriConst.VALUE_ALERT_BF) 
                          && (tempQuote[evento_id][quota_id][concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_BF)  
                          && !concessionari_non_visualizzati.includes(concessionario_codice)
                          && state.alert_attivi['BF']['abilitato']
                        ) 
                        || 
                        (
                          alert_attivato[0]
                          && state.alert_attivi['AVG']['abilitato']  
                        )
                        && temp_stato_evento.alert_sound_enabled
                        ) || souna_alert
          
        } else {
          Object.keys(tempQuote[evento_id][quota_id]).map(concessionario_code => {
            old_alert = tempQuote[evento_id][quota_id][concessionario_code]['alert'] 
            tempQuote[evento_id][quota_id][concessionario_code]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_code, tempQuote, temp_stato_evento.distance) 
            souna_alert = Boolean(!souna_alert 
                          && !(old_alert & MarcatoriConst.VALUE_ALERT_BF) 
                          && (tempQuote[evento_id][quota_id][concessionario_code]['alert'] & MarcatoriConst.VALUE_ALERT_BF) 
                          && temp_stato_evento.alert_sound_enabled
                          && !concessionari_non_visualizzati.includes(concessionario_code)
                          && state.alert_attivi['BF']['abilitato']
                          ) || souna_alert

          })
        }
        stato = {
          ...temp_stato_evento,
          timeout_cancellazione_eventi: new_timeout_cancellazione_eventi,
          quote: tempQuote,
        }
         // controllare se l'evento è visibile e il marcatore è visibile
        var index_evento = stato.eventi.findIndex(function(evento) {
          return evento.evento_id  === evento_id
        });
        if(index_evento !== -1){
          tempEventi[index_evento] = filterCheckEvento(stato, tempEventi[index_evento], quota_id)
          if(souna_alert){
            if(stato.eventi[index_evento].enabled && stato.eventi[index_evento].visible){
              index_marcatore = stato.eventi[index_evento].marcatori.findIndex(function(marcatore) {
                return Object.keys(marcatore.specialita).find(key => parseInt(marcatore.specialita[key]) === parseInt(quota_id))
              });
              if(index_marcatore !== -1){
                if(stato.eventi[index_evento].marcatori[index_marcatore].enabled){
                  suono_alert.play()
                }
              }
            }
          }
        }
      }
      if (stato.ref_list != null)
        stato.ref_list.recomputeRowHeights(0)
      return {
        ...stato,
        eventi: tempEventi,
        ultimo_aggiornamento: Date.now()
      }
    case MarcatoriActionsType.LOAD_QUOTE:
      return state
    case MarcatoriActionsType.ADD_SPECIALITA:
      tempSpecialita = []
      let changeNameSpecialita = {
        "Primo Marcatore": "1° Marcatore",
        "Primo Cartellino": "1° Cartellino",
        "Primo Ammonito": "1° Ammonito"
      }
      action.payload.forEach((spec)=>{
        tempVisible = true
        // cambio in nome della specialità prima di aggiungerlo alla struttura
        if(spec.descrizione in changeNameSpecialita){
          spec.descrizione = changeNameSpecialita[spec.descrizione]
        }
        
        // avendo già controllato che la chiave esista posso controllare direttamente che esista la specialita
        if(spec.id in local_state.impostazioni.specialita){
          tempVisible = local_state.impostazioni.specialita[spec.id].visible
        }
        
        tempSpecialita.push({...spec, visible:tempVisible})
      })
      
      // le descrizioni che sono presenti qui sono quelle cambiate per la visualizzazione non quelle presenti nel DB
      let positions = {
        "Marcatore": 0,
        "Marc. Duo": 1,
        "Marc. Plus": 2,
        "1° Marcatore": 3,
        "Doppietta": 4,
        "Tripletta": 5,
        "Assist": 6,
        "Cartellino": 7,
        "1° Cartellino": 8,
        "Ammonito": 9,
        "1° Ammonito": 10
      }
      // ordina tempSpecialita in base alla posizione
      tempSpecialita.sort((a,b) => {
        // con queste due condizioni verranno accodate alla fine le specialità che non sono presenti nell'ordinamento
        if (positions[a.descrizione] === undefined){
          return 1
        }
        if (positions[b.descrizione] === undefined){
          return -1
        }
        return positions[a.descrizione] - positions[b.descrizione]
      })
      return {
        ...state,
        specialita: tempSpecialita,
        loading: state.loading && !(state.eventi.length > 0 && state.concessionari.length > 0) // tengo conto anche del loading degli eventi e dei concessionari quando carico le specialita
                                                                              // evitando di caricare le quote degli eventi senza passare concessionari e specialita
      }
    case MarcatoriActionsType.CHANGE_VISUALIZATION_SPECIALITA:
      tempSpecialita = [...state.specialita]
      index = tempSpecialita.indexOf(action.payload)
      tempSpecialita[index].visible = !tempSpecialita[index].visible
      return {
        ...state,
        specialita: tempSpecialita
      }
    case MarcatoriActionsType.GET_SPECIALITA:
      return state
    case MarcatoriActionsType.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      }
    case MarcatoriActionsType.SET_CAMPIONATI:
      tempCampionati = campionato_generaelenco(action.payload, state.filtro_data);
      return {
        ...state,
        campionati: tempCampionati
      }
    case MarcatoriActionsType.CHANGE_VISUALIZATION_EVENTI:
      tempEventi = state.eventi
      tempCampionati = [...state.campionati]
      index = tempCampionati.indexOf(action.payload)
      tempCampionati[index].visible = !tempCampionati[index].visible
      stato = {
        ...state,
        campionati: tempCampionati,
      }
      if(state.ref_list != null)
        state.ref_list.recomputeRowHeights(0);
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento) 
      });
      return {
        ...stato,
        eventi: tempEventi,
      }
    case MarcatoriActionsType.SET_DISTANCE:
      stato = {
        ...state,
        distance: action.payload
      }
      //saveLocalState(stato)
      return stato
    case MarcatoriActionsType.CHECK_ALERT_DISTANCE:
      tempQuote = {...state.quote}
      Object.keys(tempQuote).map((evento_id)=>{
        Object.keys(tempQuote[evento_id]).map((quota_id) => {
          // prendo quello a posizione 0 che suppongo sia BETFAIR dato che il controllo da effettuare è su BF
          if(MarcatoriConst.CONCESSIONARIO_BETFAIR in tempQuote[evento_id][quota_id]){    
            Object.keys(tempQuote[evento_id][quota_id]).map( (concessionario_codice) => {
                if (!MarcatoriConst.CONFRONTO.includes(concessionario_codice)){
                  tempQuote[evento_id][quota_id][concessionario_codice]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_codice, tempQuote, state.distance)      
                }    
            })
          }
        })
          
        // tempQuote[key] = checkEventoAlert(key, tempQuote, state.distance, state.percentuale_avg)
      })
      stato = {
        ...state,
        quote: tempQuote
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if(state.alert_eye && state.ref_list != null){
        state.ref_list.recomputeRowHeights(0);
      }
      return {
          ...stato,
          eventi: tempEventi
      }
    case MarcatoriActionsType.SET_EYE_STATE:
      tempEventi = [...state.eventi]
      tempQuote = {...state.quote}
      index = null
      stato = {
        ...state,
        alert_eye: action.payload,
      }
      tempEventi.forEach(evento =>{
        evento = filterCheckEvento(stato, evento)
      })
      if(state.ref_list != null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...stato,
        eventi: tempEventi,
      }
    case MarcatoriActionsType.SET_PERCENTUALE:
      stato = {
        ...state,
        percentuale_avg: action.payload
      }
      return stato;
    case MarcatoriActionsType.CHECK_ALERT_AVG_PERCENTUALE:
      tempQuote = {...state.quote}
      // recupero i concesisonari per i quali non devo valutare la quota_media nella media stessa
      concessionari_da_non_considerare = getConcessionariNotQuotaMedia(state.concessionari)
      concessionari_non_visualizzati = getConcessionariNonVisualizzati(state.concessionari)
      Object.keys(tempQuote).map((evento_id)=>{
        Object.keys(tempQuote[evento_id]).map(quota_id => {
          tempQuote[evento_id][quota_id] = checkQuotaMediaFunc(tempQuote[evento_id][quota_id], state.percentuale_avg, concessionari_da_non_considerare, state.limite_quota_media, concessionari_non_visualizzati)
        })
      })
      tempEventi = [...state.eventi]
      stato = {
        ...state,
        quote: tempQuote
      }
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if(state.alert_eye && state.ref_list != null){
        state.ref_list.recomputeRowHeights(0);
      }
      return {
        ...stato,
        eventi: tempEventi
      }
    case MarcatoriActionsType.CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE:
      concessionari_da_non_contare = getConcessionariNotQuotaMedia(state.concessionari)
      concessionari_non_visualizzati = getConcessionariNonVisualizzati(state.concessionari) 
      quota_id = action.payload.quota_id
      evento_id = action.payload.evento_id
      tempQuote = {...state.quote}
      tempQuote[evento_id][quota_id] = checkQuotaMediaFunc(tempQuote[evento_id][quota_id], state.percentuale_avg, concessionari_da_non_considerare, state.limite_quota_media, concessionari_non_visualizzati)
      return{
        ...state,
        quote: tempQuote
      }        
    case MarcatoriActionsType.CHANGE_STATE_ALERT_SOUND:
      return {
        ...state,
        alert_sound_enabled: !state.alert_sound_enabled
      }
      case MarcatoriActionsType.DELETE_STARTED_EVENT:
        tempEventi = []
        tempQuote = {...state.quote}
        currentTimestamp = (new Date().getTime())/1000 - new Date().getTimezoneOffset()*60
        evento_rimosso = false;
        state.eventi.forEach((evento) => {
          if (evento.timestamp > currentTimestamp){
            // aggiungo nel caso l'evento non è ancora iniziato
            tempEventi.push(evento)
          } else{
            // nel caso l'evento fosse già iniziato pulisco anche la struttura delle quote
            tempQuote = cleanQuote(evento.evento_id, tempQuote)
            evento_rimosso = true;
          }
        })
        // il calcolo del nuovo timestamp di cancellazione eventi viene fatto sempre
        // in modo da evitare che nel caso in cui fosse calcolato male venisse richiamata
        // in continuazione questa action in maniera incontrollata
        new_timeout_cancellazione_eventi = waitTimeCancellazioneEventi(tempEventi)
        stato = {
          ...state,
          timeout_cancellazione_eventi: new_timeout_cancellazione_eventi,
        }
        if (evento_rimosso) {
          return {
            ...stato,
            eventi: tempEventi,
            quote: tempQuote,
          }
        } else {
          return stato;
        }
    case MarcatoriActionsType.SET_ENABLED_STATE_EVENT:
      tempEvento = action.payload.evento
      tempEventi = [...state.eventi]
      index = tempEventi.indexOf(tempEvento)
      tempEventi[index].enabled = action.payload.enabled
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi      
      }
    case MarcatoriActionsType.SET_ENABLED_STATE_PLAYER:
        tempEvento = action.payload.evento
        tempEventi = [...state.eventi]
        index = tempEventi.indexOf(tempEvento)
        index_marcatore = tempEventi[index].marcatori.indexOf(action.payload.marcatore)
        tempEventi[index].marcatori[index_marcatore].enabled = action.payload.enabled
        stato = {
          ...state,
          eventi: tempEventi
        }
        if (state.alert_eye){
          tempEventi[index] = filterCheckEvento(stato, tempEventi[index])
        }
        if (state.ref_list != null)
          state.ref_list.recomputeRowHeights(0)
        return {
          ...stato,
          eventi: tempEventi
        } 
    // case MarcatoriActionsType.SET_EYE_STATE:
    //   var ref = state.ref_list.recomputeRowHeights(0)
    //   state = {
    //     ...state,
    //     alert_eye: action.payload,
    //     ref_list: ref
    //   };
    //   return state; 
    case MarcatoriActionsType.SET_ALERT_ABILITATI:
      state = {
        ...state,
        alert_attivi: action.payload
      };
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento =>{
        evento = filterCheckEvento(state, evento)
      })
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0);
      //saveLocalState(state)     
    return {
      ...state,
      eventi: tempEventi
    };
    case MarcatoriActionsType.SET_FILTRO_TIPO_DATA:
      // per fare in modo di filtrare bene gli eventi l'elenco dei campionati deve essere generato prima
      tempEventi = [...state.eventi]
      // ridefinisco l'elenco dei campionati rispettando l'intervallo di data degli eventi
      // e cioè verranno visualizzati solo i campionati che contegono eventi nell'intervallo di data selezionato
      // genero la lista dei campionati presenti ed rigenero la lista dei campionati nel caso in cui
      tempCampionati = campionato_generaelenco(tempEventi, action.payload, [], [], state.campionati);
      stato = {
        ...state,
        filtro_data: action.payload,
        campionati: tempCampionati
      }
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0);
      return {
        ...stato,
        eventi: tempEventi,
      }
    case MarcatoriActionsType.SET_REF_LIST:
      return{
        ...state,
        ref_list: action.payload
      }
    case MarcatoriActionsType.SET_OPEN_STATE_MARCATORE:
      tempEventi = [...state.eventi]
      index_evento = tempEventi.findIndex((evento) => evento === action.payload.evento)
      index_marcatore = tempEventi[index_evento].marcatori.findIndex((marcatore) => marcatore === action.payload.marcatore)
      tempEventi[index_evento].marcatori[index_marcatore].open = action.payload.state
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi,
      }
    case MarcatoriActionsType.UPDATE_LIST:
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state
      }
    case MarcatoriActionsType.ALL_SET_OPEN_STATE_MARCATORE:
      tempEventi = [...state.eventi]
      var state_open = action.payload
      tempEventi.forEach(evento => {
        evento.marcatori.forEach(marcatore =>{
          marcatore.open = state_open;
        })
      })
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi
      }
    case MarcatoriActionsType.SET_OPEN_STATE_EVENTO:
      tempEventi = [...state.eventi];
      index_evento = tempEventi.findIndex((evento) => evento === action.payload.evento);
      tempEventi[index_evento].open = action.payload.state;
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi,
      }
    case MarcatoriActionsType.ALL_SET_OPEN_STATE_EVENTO:
      tempEventi = [...state.eventi];
      tempEventi.forEach(evento => {
        evento.open = action.payload
      })
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0)
      return {
        ...state,
        eventi: tempEventi,
      }
    case MarcatoriActionsType.SAVE_IMPOSTAZIONI:
      saveLocalState(state)
      return state
    case MarcatoriActionsType.SET_FILTRO_EVENTO_NAME:
      stato = {
        ...state,
        filtro_marcatore: '',
        filtro_evento: action.payload
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0);
      return {
        ...stato, 
        eventi: tempEventi
      }
    case MarcatoriActionsType.SET_FILTRO_MARCATORE:
      stato = {
        ...state,
        filtro_evento: '',
        filtro_marcatore: action.payload,
      }
      tempEventi = [...state.eventi]
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento)
      })
      if (state.ref_list != null)
        state.ref_list.recomputeRowHeights(0);
      return {
        ...stato, 
        eventi: tempEventi,
      }
    case MarcatoriActionsType.CHANGE_TIPO_ORDINAMENTO:
      var tipo_ordinamento = action.payload
      tempEventi = [...state.eventi]
      if (tipo_ordinamento === MarcatoriConst.TIPO_ORDINAMENTO_PER_DATA){
        // ordinare gli eventi per data
        tempEventi.sort((a, b) => {
          if (a.timestamp < b.timestamp){
            return -1
          } else if (a.timestamp > b.timestamp){
            return 1
          } else {
            return 0
          }
        })
        
      } else if (tipo_ordinamento === MarcatoriConst.TIPO_ORDINAMENTO_PER_CAMPIONATO){
        // ordinare gli eventi per campionato
        tempEventi.sort((a, b) => {
          if (a.campionato_descrizione < b.campionato_descrizione){
            return -1
          } else if (a.campionato_descrizione > b.campionato_descrizione){
            return 1
          } else {
            if (a.timestamp < b.timestamp){
              return -1
            } else if (a.timestamp > b.timestamp){
              return 1
            } else {
              return 0
            }
          }

        })
      }
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0); 
      return {
        ...state,
        eventi: tempEventi,
      }
    case MarcatoriActionsType.CHANGE_VISUALIZATION_ALL_EVENTI:
      tempEventi = state.eventi
      tempCampionati = [...state.campionati]
      tempCampionati.forEach(campionato => {
        campionato.visible = action.payload  
      })
      stato = {
        ...state,
        campionati: tempCampionati,
      }
      if(state.ref_list !== null)
        state.ref_list.recomputeRowHeights(0);
      tempEventi.forEach(evento => {
        evento = filterCheckEvento(stato, evento) 
      });
      return {
        ...stato,
        eventi: tempEventi,
      }
    case MarcatoriActionsType.SET_QUOTA_DESCRIZIONE:
      tempQuote = {...state.quote}
      evento_id = action.payload.evento_id
      quota_id = action.payload.quota_id
      concessionario_codice = action.payload.concessionario_codice
      let jsonDescrizione = action.payload.descrizione
      // controllo prima se la chiave 'quota_descrizione' è presente nel json
      // in caso contrario la chiamata può essere andara in errore
      if('quota_descrizione' in jsonDescrizione){
        if(evento_id in tempQuote){
          if(!(quota_id in tempQuote[evento_id])){
            tempQuote[evento_id][quota_id] = {}
          }
          if(!(concessionario_codice in tempQuote[evento_id][quota_id])){
            tempQuote[evento_id][quota_id][concessionario_codice] = {}
          }
          tempQuote[evento_id][quota_id][concessionario_codice]['quota_descrizione'] = jsonDescrizione['quota_descrizione']
        }
      }
      return {
        ...state,
        quote: tempQuote
      }
    case MarcatoriActionsType.SET_LIMITE_QUOTA_MEDIA:
      return {
        ...state,
        limite_quota_media: action.payload
      }
    case MarcatoriActionsType.CHANGE_VALUTA_QUOTA_MEDIA_CONCESSIONARIO:
      tempConcessionari = [...state.concessionari]
      index = tempConcessionari.indexOf(action.payload)
      tempConcessionari[index].valuta_quota_media = !tempConcessionari[index].valuta_quota_media
      return {
        ...state,
        concessionari: tempConcessionari
      }
    default:
      return state
  } 
}
export default Marcatori;
