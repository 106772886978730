import { IppicaConst } from '../../constants/ippica';



export const checkEventoAlert = (evento_id, quote, distanza_banca, distanza_punta, concessionari_da_non_considerare = []) => {
    
    // BETFAIR CHECK
    var dizionario_evento = quote[evento_id]
    Object.keys(dizionario_evento).map((quota_id)=>{
        if(IppicaConst.CONCESSIONARIO_BETFAIR in dizionario_evento[quota_id]){    
            Object.keys(dizionario_evento[quota_id]).map( (concessionario_codice) => {
                if (!IppicaConst.CONFRONTO.includes(concessionario_codice)){
                    dizionario_evento[quota_id][concessionario_codice]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_codice, quote, distanza_banca, distanza_punta)      
                }    
            })
        }
    })

    // //QUOTA_MEDIA CHECK
    // Object.keys(dizionario_evento).map((quota_id)=>{
    //     var competitor = dizionario_evento[quota_id]
    //     dizionario_evento[quota_id] = checkQuotaMediaFunc(competitor, percentuale, concessionari_da_non_considerare)
    // })


    return dizionario_evento
} 

export const checkQuotaAlertBF = (evento_id, quota_id, concessionario_codice, quote, distanza_banca, distanza_punta) => {
    if(!IppicaConst.CONFRONTO.includes(concessionario_codice)){
        if(!('alert' in quote[evento_id][quota_id][concessionario_codice])){
            quote[evento_id][quota_id][concessionario_codice]['alert'] = 0;
        }

        // controllo l'alert relativo alla quota betfair per bancare
        if(quote[evento_id][quota_id][concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF){
            if(((parseFloat(quote[evento_id][quota_id][concessionario_codice]['value']) + parseFloat(distanza_banca)) < parseFloat(quote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_BETFAIR]['value'])) || parseFloat(quote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_BETFAIR]['value']) <= 1){
                quote[evento_id][quota_id][concessionario_codice]['alert'] -= IppicaConst.VALUE_ALERT_BF;
            }
        }else{
            if(IppicaConst.CONCESSIONARIO_BETFAIR in quote[evento_id][quota_id] && parseFloat(quote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_BETFAIR]['value']) > 1){
                if((parseFloat(quote[evento_id][quota_id][concessionario_codice]['value']) + parseFloat(distanza_banca)) >= parseFloat(quote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_BETFAIR]['value'])){
                    quote[evento_id][quota_id][concessionario_codice]['alert'] += IppicaConst.VALUE_ALERT_BF;
                }
            }    
        }


        // controllo l'alert relativo alla quota di betfair per puntare, quindi valuto la quota_value_2 di BF rispetto a quella del concessionario
        if(quote[evento_id][quota_id][concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF_PUNTA){
            if(((parseFloat(quote[evento_id][quota_id][concessionario_codice]['value']) + parseFloat(distanza_punta)) < parseFloat(quote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_BETFAIR]['value_2'])) || parseFloat(quote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_BETFAIR]['value_2']) <= 1){
                quote[evento_id][quota_id][concessionario_codice]['alert'] -= IppicaConst.VALUE_ALERT_BF_PUNTA;
            }
        }else{
            if(IppicaConst.CONCESSIONARIO_BETFAIR in quote[evento_id][quota_id] && parseFloat(quote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_BETFAIR]['value_2']) > 1){
                if((parseFloat(quote[evento_id][quota_id][concessionario_codice]['value']) + parseFloat(distanza_punta)) >= parseFloat(quote[evento_id][quota_id][IppicaConst.CONCESSIONARIO_BETFAIR]['value_2'])){
                    quote[evento_id][quota_id][concessionario_codice]['alert'] += IppicaConst.VALUE_ALERT_BF_PUNTA;
                }
            }    
        }

        return quote[evento_id][quota_id][concessionario_codice]['alert']
    }
    
}
