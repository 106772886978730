import { event } from "jquery";
import { MarcatoriActionsType } from "./constants"

type MarcatoriAction = { type: string, payload: any };

export const addEventi = (evento: any): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.ADD_EVENTI,
    payload: evento,
  }
};

export const addEvento = (evento: any, evento_id, quota_id, concessionario_codice, valore): MarcatoriAction =>{
  return {
    type: MarcatoriActionsType.ADD_EVENTO,
    payload: {
      evento: evento,
      evento_id: evento_id,
      quota_id: quota_id,
      concessionario_codice: concessionario_codice,
      valore: valore
    }
  }
}

export const getEventi = (): MarcatoriAction => {
  return{
    type: MarcatoriActionsType.GET_EVENTI,
    payload: false,
  }
}

export const getEvento = (evento_id, quota_id, concessionario_codice, valore, valore_2): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.GET_EVENTO,
    payload: {
      evento_id: evento_id,
      quota_id: quota_id,
      concessionario_codice: concessionario_codice,
      valore: valore,
      valore_2: valore_2
    }
  }
}

export const addConcessionario = (concessionario: any): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.ADD_CONCESSIONARI,
    payload: concessionario
  }
}

export const getConcessionari = (): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.GET_CONCESSIONARI,
    payload: false,
  }
}

export const changeVisualizationConcessionari = (concessionario): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.CHANGE_VISUALIZATION_CONCESSIONARIO,
    payload: concessionario
  }
}

export const changeVisualizationSpecialita = (specialita): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.CHANGE_VISUALIZATION_SPECIALITA,
    payload: specialita
  }
}

export const changeVisualizationEventi = (campionato): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.CHANGE_VISUALIZATION_EVENTI,
    payload: campionato
  }
}


export const setQuota = (evento_id, quota_id, concessionario_codice, valore, valore_2): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_QUOTA,
    payload: {
      evento_id: evento_id,
      quota_id: quota_id,
      concessionario_codice: concessionario_codice,
      valore: valore,
      valore_2: valore_2
    }
  
  }
}

export const setQuoteEvento = (quote, evento_id, quote_errore): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_QUOTA_EVENTO,
    payload: {
      quote: quote,
      evento_id: evento_id,
      quote_errore: quote_errore
    }
  }
}

export const getQuoteEvento = (evento_id, concessionari, specialita): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.GET_QUOTE_EVENTO,
    payload:{
      evento_id: evento_id,
      concessionari: concessionari,
      specialita: specialita
    }
  }
}

export const addSpecialita = (specialita): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.ADD_SPECIALITA,
    payload: specialita
  }
}

export const getSpecialita = (): MarcatoriAction =>{
  return{
    type: MarcatoriActionsType.GET_SPECIALITA,
    payload: false
  }
}

export const setToken = (token): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_TOKEN,
    payload: token 
  }
}

export const setCampionati = (eventi): MarcatoriActions => {
  return {
    type: MarcatoriActionsType.SET_CAMPIONATI,
    payload: eventi
  }
}

export const setDistance = (distance): MarcatoriActions => {
  return {
    type: MarcatoriActionsType.SET_DISTANCE,
    payload: distance
  }
}

export const checkAlertDistance = (): MarcatoriActions => {
  return {
    type: MarcatoriActionsType.CHECK_ALERT_DISTANCE,
    payload: null
  }
}


export const setPercentuale = (percentuale): MarcatoriActions => {
  return {
    type: MarcatoriActionsType.SET_PERCENTUALE,
    payload: percentuale
  }
}


export const checkQuotaMedia = (): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.CHECK_ALERT_AVG_PERCENTUALE,
    payload: null
  }
}

// Action non utilizzata valutare se cancellarla direttamente
export const checkQuotaMediaEvento = (evento_id): MarcatoriActionsType => {
  return {
    type: MarcatoriActionsType.CHECK_EVENT_ALERT_AVG_PERCENTUALE,
    payload: evento_id
  }
}

export const checkQuotaMediaMarcatore = (quota_id, evento_id): MarcatoriActionsType => {
  return {
    type: MarcatoriActionsType.CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE,
    payload: {
      quota_id: quota_id,
      evento_id: evento_id
    }
  }
}


export const changeAlertSoundState = (): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.CHANGE_STATE_ALERT_SOUND,
    payload: null
  }
}

export const deleteStartedEvents = (): MarcatoriAction => {
  return {
    type:MarcatoriActionsType.DELETE_STARTED_EVENT,
    payload: null
  }
}

export const setStateEnabledEvent = (evento, enabled_state): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_ENABLED_STATE_EVENT,
    payload: {
        evento: evento,
        enabled: enabled_state
    }
  }
}

export const setStateEnabledPlayer = (evento, marcatore, enabled_state): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_ENABLED_STATE_PLAYER,
    payload: {
        evento: evento,
        marcatore: marcatore,
        enabled: enabled_state
    }
  }
}

export const setEyeState = (stato): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_EYE_STATE,
    payload: stato
  }
}

export const setAlertAbilitati = (alert_abilitati): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_ALERT_ABILITATI,
    payload: alert_abilitati
  }
}

export const setTipoFiltroData = (tipo): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_FILTRO_TIPO_DATA,
    payload: tipo
  }
}

export const setFiltroEvento = (filtro_evento): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_FILTRO_EVENTO_NAME,
    payload: filtro_evento
  }
}

export const setRefList = (ref): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_REF_LIST,
    payload: ref
  }
} 

export const setStateOpenMarcatore = (state, evento, marcatore): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_OPEN_STATE_MARCATORE,
    payload: {
      state: state,
      evento: evento,
      marcatore: marcatore
    }
  }
}

export const allSetStateOpenMarcatore = (state): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.ALL_SET_OPEN_STATE_MARCATORE,
    payload: state
  }
}

export const setStateOpenEvento = (state, evento): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_OPEN_STATE_EVENTO,
    payload: {
      state: state,
      evento: evento,
    }
  }
}

export const allSetStateOpenEvento = (state): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.ALL_SET_OPEN_STATE_EVENTO,
    payload: state
  }
}

export const updateList = (): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.UPDATE_LIST,
    payload: null,
  }
}

export const saveImpostazioni = (): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SAVE_IMPOSTAZIONI,
    payload: null,
  }
}

export const setFiltroMarcatore = (filtro_marcatore): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_FILTRO_MARCATORE,
    payload: filtro_marcatore
  }
}

export const changeTipoOrdinamento = (tipo): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.CHANGE_TIPO_ORDINAMENTO,
    payload: tipo
  }
}

export const changeVisualizationEventiAll = (checked): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.CHANGE_VISUALIZATION_ALL_EVENTI,
    payload: checked
  }
}

export const setQuotaDescrizione = (evento_id, quota_id, concessionario_codice, descrizione): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_QUOTA_DESCRIZIONE,
    payload: {
      evento_id: evento_id,
      quota_id: quota_id,
      concessionario_codice: concessionario_codice,
      descrizione: descrizione
    }
  }
}

export const setLimiteQuotaMedia = (limite): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.SET_LIMITE_QUOTA_MEDIA,
    payload: limite
  }
}

export const changeValutaQuotaMediaConcessionario = (concessionario): MarcatoriAction => {
  return {
    type: MarcatoriActionsType.CHANGE_VALUTA_QUOTA_MEDIA_CONCESSIONARIO,
    payload: concessionario
  }
}