import { put, takeEvery, all, fork, select } from 'redux-saga/effects'
import { ServersConst } from '../../constants';
import { MarcatoriActionsType } from './constants';
import * as MarcatoriActions from './actions';


const token_state = state=> state.Marcatori.token;
const quote_state = state => state.Marcatori.quote;

function* fetchConcessionari(): any {
  const token = yield select(token_state);
  const requestOption = {
    method: 'POST',
    body: JSON.stringify({'token': token}),
    headers: {
        'Accept': 'application/json',
    },
  }  
  const json_respone = yield fetch(ServersConst.SERVER_MARCATORI + ServersConst.END_POINT_GET_CONCESSIONARI, requestOption).then(response => response.json(), );
  yield put(MarcatoriActions.addConcessionario(json_respone));
}


function* concessionariWatcher(): any {
  yield takeEvery(MarcatoriActionsType.GET_CONCESSIONARI, fetchConcessionari)
}


function* fetchEventi(): any {
  const token = yield select(token_state);
  const requestOption = {
    method: 'POST',
    body: JSON.stringify({'token': token}),
    headers: {
        'Accept': 'application/json',
    },
  }
  const json_respone = yield fetch(ServersConst.SERVER_MARCATORI + ServersConst.END_POINT_GET_EVENTI,requestOption).then(response => response.json(), );
  yield put(MarcatoriActions.addEventi(json_respone));
}


function* fetchEvento(params): any {
  const token = yield select(token_state);
  const requestOption = {
    method: 'POST',
    body: JSON.stringify({'token': token, 'evento_id': params.payload.evento_id}),
    headers: {
        'Accept': 'application/json',
    },
  }
  const json_respone = yield fetch(ServersConst.SERVER_MARCATORI + ServersConst.END_POINT_GET_EVENTO, requestOption).then(response => response.json(), );
  yield put(MarcatoriActions.addEvento(json_respone, params.payload.evento_id, params.payload.quota_id, params.payload.concessionario_codice, params.payload.valore, params.payload.valore_2));
}


function* eventiWatcher(): any {
  yield takeEvery(MarcatoriActionsType.GET_EVENTI, fetchEventi)
  yield takeEvery(MarcatoriActionsType.GET_EVENTO, fetchEvento)
}

function* fetchQuote(params): any{
  const token = yield select(token_state);
  var quote_errore = false;
  const requestOption = {
      method: 'POST',
      body: JSON.stringify({'token':token, 'evento_id': params.payload.evento_id, 'concessionari': params.payload.concessionari, 'specialita': params.payload.specialita}),
      headers: {
          'Accept': 'application/json',
      },
  }
  const json_respone = yield fetch(ServersConst.SERVER_MARCATORI + ServersConst.END_POINT_GET_QUOTE_FOREVENTO, requestOption)
  .then(response => {
    if(response.status != 200){
      quote_errore = true;
      return [];
    }
    return response.json()
  })
  .catch(error => {
    quote_errore = true;
    return [];
  });
  yield put(MarcatoriActions.setQuoteEvento(json_respone, params.payload.evento_id, quote_errore))
}


// fecth che recupera la quota_descrizione di un dato competitor se essa non è presente
function* fetchQuotaDescrizione(params): any{
  const quote = yield select(quote_state);
  if(params.payload.evento_id in quote 
    && 
      (
        !(params.payload.quota_id in quote[params.payload.evento_id]) || 
        !(params.payload.concessionario_codice in quote[params.payload.evento_id][params.payload.quota_id]) ||
        !('quota_descrizione' in quote[params.payload.evento_id][params.payload.quota_id][params.payload.concessionario_codice]) ||
        quote[params.payload.evento_id][params.payload.quota_id][params.payload.concessionario_codice]['quota_descrizione'] === ''
      )
    )
  {
    const token = yield select(token_state);
    const requestOption = {
        method: 'POST',
        body: JSON.stringify({'token':token, 'evento_id': params.payload.evento_id, 'concessionario': params.payload.concessionario_codice, 'quota_id': params.payload.quota_id}),
        headers: {
            'Accept': 'application/json',
        },
    }
    const json_respone = yield fetch(ServersConst.SERVER_MARCATORI + ServersConst.END_POINT_GET_QUOTA_DESCRIZIONE, requestOption)
    .then(response => {
      if(response.status != 200){
        return {};
      }
      return response.json()
    })
    .catch(error => {
      return {};
    });
    // in caso di errore la richiesta mi ritorna un json vuoto
    yield put(MarcatoriActions.setQuotaDescrizione(params.payload.evento_id,  params.payload.quota_id,  params.payload.concessionario_codice, json_respone))
  }
}
  

function* quoteWatchers(): any{
  yield takeEvery(MarcatoriActionsType.GET_QUOTE_EVENTO, fetchQuote);
  yield takeEvery(MarcatoriActionsType.SET_QUOTA, fetchQuotaDescrizione);
}

function* fetchSpecialita() {
  const token = yield select(token_state);
  const requestOption = {
    method: 'POST',
    body: JSON.stringify({'token': token}),
    headers: {
        'Accept': 'application/json',
    },
  }
  const json_respone = yield fetch(ServersConst.SERVER_MARCATORI + ServersConst.END_POINT_GET_SPECIALITA,requestOption).then(response => response.json(), );
  yield put(MarcatoriActions.addSpecialita(json_respone));
}


function* specialitaWatcher() {
  yield takeEvery(MarcatoriActionsType.GET_SPECIALITA, fetchSpecialita)
}


function* MarcatoriSaga(): any {
    yield all([
        fork(concessionariWatcher),
        fork(eventiWatcher),
        fork(quoteWatchers),
        fork(specialitaWatcher)
    ]);
}

  
export default MarcatoriSaga;
