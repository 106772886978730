import { event } from "jquery"
import { IppicaConst } from "../../constants/ippica"

export  const getMaxOdds = (odds_conc, concessionari_da_non_contare = []) => {
   var value = 0
    Object.keys(odds_conc).map((key)=>{
        if(!IppicaConst.CONFRONTO.includes(key) && key != IppicaConst.CONCESSIONARIO_VIRTUALE){
            if(!concessionari_da_non_contare.includes(key)){
                if(parseFloat(odds_conc[key]['value'])>value){
                    value = odds_conc[key]['value']
                }
            }
        }
        
   })
   return value
}

export const getEventiVisibili = (eventi, visualizza_quote_presenti) => {
  var eventi_visualizzati = []
  eventi.forEach(evento => {
    if(evento.visible && evento.enabled && (evento.quote_presenti || visualizza_quote_presenti)){
      eventi_visualizzati.push(evento);
    }
  })
  return eventi_visualizzati;
}

export const getConcessionariNonVisualizzati = (lista_concessionari) => {
    var concessionari_da_non_contare = []
    lista_concessionari.forEach((concessionario)=>{
        if(!concessionario.visible){
          concessionari_da_non_contare.push(concessionario.concessionario_codice)
        }
      })

    return concessionari_da_non_contare
}

export const getCampionatiVisualizzati = (lista_campionati) => {
    var campionati_visualizzati = []
    lista_campionati.forEach((campionato)=>{
        if(campionato.visible){
            campionati_visualizzati.push(campionato.campionato_id)
        }
      })

    return campionati_visualizzati

}

export const getSpecialitaAttive = (lista_specialita) => {
  var specialita_visualizzate = []
  lista_specialita.forEach(specialita => {
    if(specialita.visible){
      specialita_visualizzate.push(parseInt(specialita.id));
    }
  })
  return specialita_visualizzate
}


export const cleanQuote = (evento_id, quote_list) => {
    // funzione che permette di rimuovere un intero evento e le sue quote dall'insieme delle quote presenti
    if(evento_id in quote_list){
        delete quote_list[evento_id]
    }
    return quote_list
}

export const getFilterDatesByType = (tipo) => {
    var start_date = 1
    var end_date = 1
    var get_all = false
    switch(tipo){
        case 0:
          start_date = (new Date()).getTime()/1000
          end_date = (new Date()).setHours(23, 59,59)/1000
          break
        case 1:
          start_date = (new Date()).getTime()/1000
          end_date = new Date()
          let actual_day = end_date.getDate()
          end_date.setHours(23, 59,59)
          end_date.setDate(actual_day + 1)
          end_date = end_date.getTime()/1000
          break
        case 2:
          get_all = true
          break
      }
    return {'start_date': start_date, 'end_date': end_date, 'get_all': get_all}
}


// se quote_id sarà 0 allora si controlleranno tutte le quote
// se quote_id è valorizzato allora si controlleranno le quote del singolo competitor
export const filterCheckEvento = (state, evento, quota_id=0) => {
  if(evento.quote_presenti || state.visualizza_quote_presenti){
    // controllo il campionato
    var check_filter_evento = true
    var campionati_visualizzati = []
    var campionato_check = true
    if (state.campionati.length > 0) {
      campionati_visualizzati = getCampionatiVisualizzati(state.campionati);
      campionato_check = campionati_visualizzati.includes(evento.campionato_id)
    }
    
    if(check_filter_evento && !campionato_check){
      check_filter_evento = false
    }


    // controllo il testo di ricerca
    if(check_filter_evento && state.filtro_evento){
      check_filter_evento = evento.descrizione.toUpperCase().includes(state.filtro_evento.toUpperCase())
    }


    // controllo il testo di ricerca del competitor per vedere se visualizzare l'evento
    if(check_filter_evento && state.filtro_competitor){
      check_filter_evento = false
      evento.marcatori.forEach(competitor => {
        if(!check_filter_evento)
          check_filter_evento = competitor.quota_descrizione.toUpperCase().includes(state.filtro_competitor.toUpperCase())
      })
    }

    // controlla la data
    if(check_filter_evento && state.filtro_data != null){
      var dates = getFilterDatesByType(state.filtro_data);
      check_filter_evento = (
        (
          (
            evento.timestamp >= dates.start_date 
            && evento.timestamp<= dates.end_date
          ) 
          || dates.get_all
        ) 
      );

    }
    
    // inizio il controllo sulle quote 
    if(check_filter_evento){
      var check_competitor = !state.alert_eye
      var index_competitor = -1
      var concessionari_da_non_considerare = getConcessionariNonVisualizzati(state.concessionari);
      var specialita_visualizzate = getSpecialitaAttive(state.specialita)
      // controllo gli alert per la singola quota id
      if(Object.keys(state.quote).length !== 0){
        if(evento.evento_id in state.quote){
          if(quota_id && quota_id in state.quote[evento.evento_id]){
            state.concessionari.forEach(concessionario => {
              if(!concessionari_da_non_considerare.includes(concessionario.concessionario_codice)){
                if(!check_competitor){
                  //trovare il competitor che ha la quota_id dall'elenco degli eventi
                  index_competitor = evento.marcatori.findIndex(function(competitor) {
                    return Object.keys(competitor.specialita).find(key => parseInt(competitor.specialita[key]) === quota_id)
                  });
                  Object.keys(evento.marcatori[index_competitor].specialita).map((specialita_id) => {
                    if (specialita_visualizzate.includes(parseInt(specialita_id)) && evento.specialita_attive[specialita_id]){
                        if(!check_competitor){
                          check_competitor = 
                          (
                            evento.marcatori[index_competitor].specialita[specialita_id] in state.quote[evento.evento_id]
                            && 
                            concessionario.concessionario_codice in state.quote[evento.evento_id][evento.marcatori[index_competitor].specialita[specialita_id]]
                            &&
                            'alert' in state.quote[evento.evento_id][evento.marcatori[index_competitor].specialita[specialita_id]][concessionario.concessionario_codice] 
                            &&
                            (
                              (((state.quote[evento.evento_id][evento.marcatori[index_competitor].specialita[specialita_id]][concessionario.concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF) == IppicaConst.VALUE_ALERT_BF) && state.alert_attivi['BF']['abilitato'])
                              ||
                              (((state.quote[evento.evento_id][evento.marcatori[index_competitor].specialita[specialita_id]][concessionario.concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF_PUNTA) == IppicaConst.VALUE_ALERT_BF_PUNTA) && state.alert_attivi['BF_PUNTA']['abilitato'])
                               
                            )
                          ) 
                        }
                      }})
                    
                    // check_competitor = (
                    //   'alert' in state.quote[evento.evento_id][quota_id][concessionario.concessionario_codice] 
                    //   &&
                    //   (
                    //     (state.quote[evento.evento_id][quota_id][concessionario.concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF && state.alert_attivi['BF']['abilitato']) 
                    //   ) 
                    // )
                  }
                }
            })
            // recupero l'indice del competitor scorrendo l'array presente nell'evento conoscendo la quota_id
            index_competitor = evento.marcatori.findIndex(function(competitor) {
              return Object.keys(competitor.specialita).find(key => parseInt(competitor.specialita[key]) === quota_id)
            });
            if (index_competitor>=0) {
              evento.marcatori[index_competitor].visible = Boolean(check_competitor)
            }
          } else {
            // controllo tutti i singoli competitors
            evento.marcatori.forEach(competitor => {
              if(competitor.enabled){
                check_competitor = !state.alert_eye
                // prendo tutte le quote id relative ai competitors
                Object.keys(competitor.specialita).map(key_specialita => {
                  if (specialita_visualizzate.includes(parseInt(key_specialita)) && evento.specialita_attive[parseInt(key_specialita)]){
                    var temp_quota_id = competitor.specialita[key_specialita]
                    if(temp_quota_id in state.quote[evento.evento_id]){
                      Object.keys(state.quote[evento.evento_id][temp_quota_id]).map(concessionario_codice => {
                        if(!concessionari_da_non_considerare.includes(concessionario_codice)){
                          if(!check_competitor){
                            check_competitor = (
                              'alert' in state.quote[evento.evento_id][temp_quota_id][concessionario_codice] 
                              &&
                              (
                                (state.quote[evento.evento_id][temp_quota_id][concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF && state.alert_attivi['BF']['abilitato'])
                                ||
                                (state.quote[evento.evento_id][temp_quota_id][concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF_PUNTA && state.alert_attivi['BF_PUNTA']['abilitato']) 
                              )
                            )
                          }
                        }
                      })
                    }
                  }
                })
              }else{
                check_competitor = competitor.enabled
              }
              competitor.visible = Boolean(check_competitor)
            })
          }
          //controlla se l'evento ha almeno un competitor visualizzato, altrimenti nasconde l'evento stesso
          check_filter_evento = false
          var enabled_competitor = false
          evento.marcatori.forEach(competitor => {
            if(competitor.enabled){
              enabled_competitor = true
              return
            }
          })
          if(enabled_competitor){
            evento.marcatori.forEach(competitor => {
              if(competitor.visible){
                check_filter_evento = true
                return
              }
            })
          }
        }else{
          check_filter_evento = !state.alert_eye
        }
      }
      // setto a false tutti gli altri competitors che non rispettano il filtro 
      if(state.filtro_competitor){
        evento.marcatori.forEach(competitor => {
          if(competitor.visible)
            competitor.visible = Boolean(competitor.quota_descrizione.toUpperCase().includes(state.filtro_competitor.toUpperCase()))
        })
      }
    }
    evento.visible = check_filter_evento
  }
  return evento
}

// dato gli eventi e il filtro per data utilizzo (oggi, domani o tutti), ritorna l'insieme dei
// campionati che degli eventi che soddisfano il filtro
// se viene indicato il parametro 'campionati_id_presenti', aggiunge solo i campionati che non sono già presenti
// 'check_campionati_presenti è un array che indica i campionati presenti ma che viene utilizzato dopo la creazione dei campionati per verificare
// se è necessario ricopiarsi le impostazioni presenti prima'
export const campionato_generaelenco = (eventi, filtro_data, lista_specialita, campionati_presenti = [], campionati_id_presenti = [], check_campionati_presenti = [], visualizza_quote_presenti = false) =>
{
	var tempCampionati = [...campionati_presenti];
	var campionatiInseriti = [...campionati_id_presenti];
  var specialita_attive = {}
  var check_filter_evento;
  var dates = getFilterDatesByType(filtro_data);

  specialita_attive = inizializzaDizionarioSpecialitaAttiveEventoCampionato(lista_specialita)

	eventi.forEach(evento => {	
    if(!campionatiInseriti.includes(evento.campionato_id)) {
      check_filter_evento = (
        (
        (
          evento.timestamp >= dates.start_date 
          && evento.timestamp<= dates.end_date
        ) 
        || dates.get_all
        ) 
        && (evento.quote_presenti || visualizza_quote_presenti) 
      );

      if(check_filter_evento){
        var campionato = {}
        campionato['campionato_id'] = evento.campionato_id
        campionato['campionato_descrizione'] = evento.campionato_descrizione
        campionato['visible'] = true
        campionato['specialita_attive'] = {...specialita_attive}
        tempCampionati.push(campionato)
        campionatiInseriti.push(campionato['campionato_id'])
      }
    }
	})
  // nel caso in cui fosse passata questa variabile, sovrascrivo i campionati che sono stati creati
  // con quelli già presenti se ci sono salvando le impostazioni precedenti
  if(check_campionati_presenti.length > 0){
    tempCampionati.forEach((campionato, index) => {
      var campionato_presente = check_campionati_presenti.find(function(campionato_presente) {
        return campionato_presente.campionato_id === campionato.campionato_id
      })
      if(campionato_presente !== undefined){
        tempCampionati[index] = campionato_presente
      }
    })
  }
  // ordinamento per nome dei campionati
	tempCampionati.sort((a, b) => (a.campionato_descrizione > b.campionato_descrizione) ? 1 : -1)  
  return tempCampionati
}


export const getHeighRowEventoCompetitor = (evento, tipo_visualizzazione, concessionari, visualizza_quote_presenti = false) =>{
  var result_height = 0

  if(evento.enabled && evento.visible && (evento.quote_presenti || visualizza_quote_presenti)){
    if (evento.open){
      var concessionari_visibili = 0
      var height_concessionario = 19.69 // uguale per tutte le visualizzazioni

      concessionari.forEach(concessionario => {
        if(concessionario.visible)
          concessionari_visibili ++;
      })


      var height_visualization_competitor_closed = 0
      var height_visualization_competitor_open = 0
      var height_visualization_riga = IppicaConst.ALTEZZA_RIGA_EVENTO

      var total_height_visualization_competitors = 0
      var competitors_riga = 0
      var competitors_visibili = 0
      var last_height = 0
      var resto = 0

      if(tipo_visualizzazione == 0){
        height_visualization_competitor_closed = IppicaConst.ALTEZZA_RIGA_COMPETITOR_1_COLONNA_CHIUSA
        height_visualization_competitor_open = (concessionari_visibili * height_concessionario) + 10
        competitors_riga = 1
      }else if (tipo_visualizzazione & 1){
        height_visualization_competitor_closed = IppicaConst.ALTEZZA_RIGA_COMPETITOR_1_COLONNA_CHIUSA
        height_visualization_competitor_open = (concessionari_visibili * height_concessionario) + IppicaConst.ALTEZZA_INTESTAZIONE_SPECIALITA
        competitors_riga = 2
      }else{
        competitors_riga = 3
        height_visualization_competitor_closed = IppicaConst.ALTEZZA_RIGA_COMPETITOR_1_COLONNA_CHIUSA
        height_visualization_competitor_open = (concessionari_visibili * height_concessionario) + IppicaConst.ALTEZZA_INTESTAZIONE_SPECIALITA
      }
      var index_save_height = 0
      var max_height = 0
      evento.marcatori.forEach((competitor)=>{
        if (competitor.enabled && competitor.visible){
          // indice che permette di arrivare al conteggio del numero di competitors per riga
          index_save_height ++;
          if(competitor.open){
            max_height = height_visualization_competitor_open
            last_height = height_visualization_competitor_open
          }else{
            if(max_height == 0){
              max_height = height_visualization_competitor_closed;
            }
            last_height = height_visualization_competitor_closed
          }
          if(index_save_height == competitors_riga){
            total_height_visualization_competitors = total_height_visualization_competitors + max_height;
            index_save_height = 0
            max_height = 0
          }
          competitors_visibili ++
        }
      })
      resto = competitors_visibili%competitors_riga  
      if(resto == 0){
        result_height = height_visualization_riga + total_height_visualization_competitors
      }else{
        result_height = height_visualization_riga + total_height_visualization_competitors + last_height;
      }
    }else{
      result_height = IppicaConst.ALTEZZA_RIGA_EVENTO
    }
  }
  return result_height
}


export const getLocalState = () => {
  var result = {}

  var local_state = window.localStorage.getItem(IppicaConst.KEY_STATE)
  if(local_state){
    result = JSON.parse(local_state)
  }
  // controllo che tutte le chiavi necessarie siano presenti
  if(!('impostazioni' in result)){
    result['impostazioni'] = {}
  }
  if(!('distanza_banca' in result['impostazioni'])){
    result['impostazioni']['distanza_banca'] = 0
  }
  if(!('distanza_punta' in result['impostazioni'])){
    result['impostazioni']['distanza_punta'] = 0
  }
  if(!('alert_abilitati' in result['impostazioni'])){
    result['impostazioni']['alert_abilitati'] = {
      'BF':
        {
          'abilitato': true,
          'colore': 'bf-alert-banca',
          'descrizione': 'Alert BETFAIR BANCA'
        }, 
      'BF_PUNTA': 
        {
          'abilitato': false,
          'colore': 'alert-bf-punta',
          'descrizione': 'Alert BETFAIR PUNTA'
        }
    }
  }else{
    if(!('BF' in result['impostazioni']['alert_abilitati'])){
      result['impostazioni']['alert_abilitati']['BF'] = {
        'abilitato': true,
        'colore': 'bf-alert-banca',
        'descrizione': 'Alert BETFAIR BANCA'
      }
    }
    if(!('BF_PUNTA' in result['impostazioni']['alert_abilitati'])){
      result['impostazioni']['alert_abilitati']['BF_PUNTA'] = {
        'abilitato': true,
        'colore': 'alert-bf-punta',
        'descrizione': 'Alert BETFAIR PUNTA'
      }
    }
  }
  return result
}

export const saveLocalState = (state) => {
  var result = {
    impostazioni: {
      distanza_banca: state.distance_banca,
      distanza_punta: state.distance_punta,
      alert_abilitati: state.alert_attivi,
    }
  }
  window.localStorage.setItem(IppicaConst.KEY_STATE, JSON.stringify(result));
}

export const inizializzaEventoCampi = (evento, lista_specialita) => {
  var tempCompetitors= []
  var tempEvento = {}
  var element = {}
  // costruisco un dizionario con chiave le specialita id

  var specialita_attive = inizializzaDizionarioSpecialitaAttiveEventoCampionato(lista_specialita)

  evento.marcatori.forEach(competitor => {
    element = {
      ...competitor, 
      visible: true,
      enabled: true,
      open: true

    }
    tempCompetitors.push(element)  
  })

  tempEvento = {
    ...evento,
    visible: true,
    enabled: true,
    open: true,
    marcatori: tempCompetitors,
    specialita_attive: specialita_attive,
    errore_quote: false,
    quote_presenti: evento.num_quote > 0,
  }

  return tempEvento
}


export const ordinaCompetitorPerFavorito = (evento, quote_evento) => {
    let quote_da_ordinare = []
    let evento_id = evento.evento_id
    // popolo l'array quote_da_ordinare inserendo in maniera ordinata le quote del concessionario
    // scelto per l'ordinamento
    Object.keys(quote_evento[evento_id]).map((quota_id) => {
      // costruisco il singolo elemento dell'array quote_da_ordinare
      let element = {
        [quota_id]: 0
      }
      if (IppicaConst.CONCESSIONARIO_ORDINAMENTO_FAVORITO in quote_evento[evento_id][quota_id]){
        element[quota_id] = parseFloat(quote_evento[evento_id][quota_id][IppicaConst.CONCESSIONARIO_ORDINAMENTO_FAVORITO]['value'])
      }
      quote_da_ordinare.push(element)
    })


    // ordino quote_da_ordinare in maniera crescente
    quote_da_ordinare.sort(function(a, b) {
      return a[Object.keys(a)[0]] - b[Object.keys(b)[0]];
    })

    // prendo i marcatori dell'evento
    let marcatori = [...evento.marcatori]
    // ordina i marcatori in base all'ordinamento di quote_da_ordinare
    
    // li ordino in base al valore delle quote della sola specialita id definita
    let marcatori_ordinati = []
    quote_da_ordinare.forEach((quota) => {
      // recupero la chiave che definisce la quota_id
      let quota_id = Object.keys(quota)[0]
      
      // se il valore è maggiore di 1 allora la quota è presente
      if(quota[quota_id] > 1){
        // trova la quota id in marcatori
        let marcatore = marcatori.find((marcatore) => {
          return parseInt(marcatore.specialita[IppicaConst.SPECIALITA_ORDINAMENTO_FAVORITO]) === parseInt(quota_id)
        })
        if(marcatore != undefined){
          marcatori_ordinati.push(marcatore)
          // rimuovo man mano i marcatori dall'array marcatori
          // poichè nel caso in cui una quota non fosse presente ma il 
          // marcatore si, non lo aggiungerei alla nuova lista dei marcatori
          let index = marcatori.indexOf(marcatore)
          marcatori.splice(index, 1)
        }
      }
    })
    // concateno i marcatori ordinati e trovati con quelli che non avevano una corrispondenza in quote o la loro quota era < 1
    return marcatori_ordinati.concat(marcatori)
}


export const inizializzaDizionarioSpecialitaAttiveEventoCampionato = (lista_specialita) => {
  var specialita_attive = {}
  lista_specialita.forEach(specialita => {
      specialita_attive[specialita.id] = true
  })
  return specialita_attive
}


export const setSpecialitaAttivePerCampionato = (eventi, campionato_id, dict_specialita_attive) => {
  eventi.forEach((evento, index) => {
    if(evento.campionato_id === campionato_id){
      Object.keys(dict_specialita_attive).forEach(specialita_id => {
        eventi[index].specialita_attive[specialita_id] = dict_specialita_attive[specialita_id]
      })
    }
  })
  return eventi
}

export const verificaQuotePresentiEvento = (quote_evento) => {
  // verifico che ci siano delle quote valide all'interno delle quote di un evento
  // basta controllare se le quote relative al concessionario_virtuale (MaxOdds) sono valorizzate
  var presenti = false
  if(Object.keys(quote_evento).length > 0){
    Object.keys(quote_evento).forEach((quota_id) => {
      presenti = (quote_evento[quota_id][IppicaConst.CONCESSIONARIO_VIRTUALE]['value'] > 1)
      if(presenti) return;
    })
  }
  return presenti

}

export const valuateAlerts = (old_alert, new_alert, alert_attivi) =>{
  return(
      !(old_alert & IppicaConst.VALUE_ALERT_BF)
      &&
      (new_alert & IppicaConst.VALUE_ALERT_BF)
      &&
      (alert_attivi['BF']['abilitato'])
    ) 
    ||
    (
      !(old_alert & IppicaConst.VALUE_ALERT_BF_PUNTA)
      &&
      (new_alert & IppicaConst.VALUE_ALERT_BF_PUNTA)
      &&
      (alert_attivi['BF_PUNTA']['abilitato'])
    )

}