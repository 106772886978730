import React from 'react';  
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'  
import './index.css';  
import App from './App';  
import Marcatori from './pages/Marcatori';  
import Home from './pages/Home';  
import Ippica from './pages/Ippica';
  
const routing = (  
  <Router>  
      <Routes>
        <Route exact path="/" element={<App/>} />
        <Route path="/marcatori" element={<Marcatori/>} />  
        <Route path="/home" element={<Home/>} /> 
        <Route path="/ippica" element={<Ippica/>}/> 
      </Routes>  
  </Router>  
)

export default routing;
