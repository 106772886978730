import React from 'react';
import {Table} from 'react-bootstrap'
import CompetitorQuotaTabella from './CompetitorQuotaTabella';
import CompetitorQuotaBF from './CompetitorQuotaBF';
import { IppicaConst } from '../../constants/ippica';
import  {useDispatch} from 'react-redux';
import * as IppicaActions from '../../redux/ippica/actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const RowCompetitor = (props) => {
    const competitor = props.competitor
    const evento_id = props.evento_id
    const classColumns = props.classColumns
    
    const dispatch = useDispatch()

    const checkExistValue = (evento_id, quota_id, concessionario_codice, quote) =>{
        return quote && evento_id in quote && quota_id in quote[evento_id] && concessionario_codice in quote[evento_id][quota_id]
      }

  const CellaQuotaComponent = (check_exist, concessionario_codice, specialita_id, alert_class) => {
    /* costruisco la cella quota che deve essere visualizzata in base al concessionario che deve essere visualizzato
      - check_exist: indica se la quota esiste o meno
      - concessionario_codice: codice del concessionario
      - specialita_id: id della specialita
      - alert_class: classe da applicare alla cella in caso di alert
    */    
    let cellaQuota = <></>
    if(check_exist){
      if(concessionario_codice != IppicaConst.CONCESSIONARIO_BETFAIR){
        if (props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['value']>0){
          cellaQuota = <CompetitorQuotaTabella alert_class={alert_class} quota={props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['value']}></CompetitorQuotaTabella>
          
        }else{
          cellaQuota = '-'
        }
      }else{
        let quota = 0
        let quota_2 = 0
        if(props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['value']>0){
          quota = props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['value']
        }
        if(props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['value_2']>0){
          quota_2 = props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['value_2']
        }
        cellaQuota = <CompetitorQuotaBF quota={quota} quota_2={quota_2}></CompetitorQuotaBF>
      }
      
    }else{
      cellaQuota = '-'
    }
    return cellaQuota
  }
    

  const getAlertClass = (check_exist, concessionario_codice, specialita_id) => {
    /* 
    Indico la classe da applicare alla cella in caso di alert, la priorità verrà data a BF_BANCA
      - check_exist: indica se la quota esiste o meno
      - concessionario_codice: codice del concessionario
      - specialita_id: id della specialita
    */
    let returnClass = ''  
    if(check_exist){
        if(props.evento.specialita_attive[String(specialita_id)]
          && props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF
          && props.alert_abilitati['BF']['abilitato']){
            returnClass = ' bf-alert-banca '
        }else if(props.evento.specialita_attive[String(specialita_id)]
        && props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['alert'] & IppicaConst.VALUE_ALERT_BF_PUNTA
        && props.alert_abilitati['BF_PUNTA']['abilitato']){
          returnClass = ' bf-alert-punta '
        }
      }
    return returnClass;
  }
  
  
  const getStyleTd = (check_exist, concessionario_codice, specialita_id) =>{
    /*
      Ritorno lo stile delle colonne in base alla presenza o meno dell'alert inserendo il puntatore
    */
    let style = {}
    if(check_exist && props.evento.specialita_attive[String(specialita_id)] && props.quote[props.evento.evento_id][competitor.specialita[specialita_id]][concessionario_codice]['alert'] > 0)
      style.cursor = "pointer"
    if(!props.evento.specialita_attive[String(specialita_id)])
      style.fontWeight = "Normal"
    style.borderRight = "1px solid #ccc"
    return style;
  }

    return (
      competitor.visible && competitor.enabled &&
        <div className={classColumns + ' card my-1 mx-1'} key = {competitor.quota_descrizione + evento_id + '1'} >
                  <div key = {competitor.quota_descrizione + evento_id + '3'} className={'fw-bold fs-marcatore row'}>
                  <div className='col-3 text-nowrap overflow-hidden ps-1'>
                  <span style={{cursor:'pointer'}}><i className="bi bi-x-lg p-1" onClick = {()=>{
                      dispatch(IppicaActions.setStateEnabledPlayer(props.evento, competitor, false))
                    }
                  }
                    title='Elimina cavallo'
                  ></i></span>
                  <span title={(competitor.open? 'Chiudi cavallo': 'Apri cavallo')} style={{cursor:'pointer', userSelect:'none'}} onClick = {()=>{
                          dispatch(IppicaActions.setStateOpenCompetitor(!competitor.open, props.evento, competitor));
                  }}>{competitor.quota_descrizione}</span>
                  </div>
                  <div className='col-9'>
                  {!competitor.open && <>
                      <Table className='table-borderless mb-0 w-auto' align={'right'} striped="">
                        <tbody key = {competitor.quota_descrizione + evento_id + '5'}>
                              <tr>
                                <td width={150}   className='align-middle fs-quote text-muted pe-3'  align={'right'}>
                                </td>
                                {props.specialita && props.specialita.map((spec)=>
                                  {
                                    var check_exist = checkExistValue(props.evento.evento_id, competitor.specialita[spec.id],IppicaConst.CONCESSIONARIO_VIRTUALE, props.quote);
                                    // recupero le classi da fornire alla cella in caso di alert
                                    var alert_class = getAlertClass(check_exist, IppicaConst.CONCESSIONARIO_VIRTUALE, spec.id)
                                    return (
                                      spec.visible && 
                                      <td 
                                      
                                      key = {IppicaConst.CONCESSIONARIO_VIRTUALE + spec.id + evento_id} 
                                      className={'fs-specialita fs-quote text-center' 
                                                + (!props.evento.specialita_attive[String(spec.id)]? ' text-muted font-weight-normal': '')
                                                }
                                      onClick={()=>{
                                        if(check_exist && props.evento.specialita_attive[String(spec.id)] && props.quote[props.evento.evento_id][competitor.specialita[spec.id]][IppicaConst.CONCESSIONARIO_VIRTUALE]['alert'] > 0){
                                          dispatch(IppicaActions.setStateOpenCompetitor(!competitor.open, props.evento, competitor));
                                        }
                                      }}
                                      style={getStyleTd(check_exist, IppicaConst.CONCESSIONARIO_VIRTUALE, spec.id)}
                                      width={IppicaConst.LARGHEZZA_SPECIALITA}>
                                      {CellaQuotaComponent(check_exist, IppicaConst.CONCESSIONARIO_VIRTUALE, spec.id, alert_class)
                                      }
                                      </td>

                                    );   
                                  }
                                  
                                )}
                              </tr>
                          </tbody>
                      </Table>
                      </> }
                      {competitor.open && <>
                      <Table className='table-borderless mb-0 w-auto' align={'right'} striped="">
                      
                        <tbody key = {competitor.quota_descrizione + evento_id + '5'}>
                        {props.tipoVisualizzazione != 0 ? <tr>
                        <td width={90}></td>
                        {props.specialita && props.specialita.map((spec)=>
                          spec.visible && <td key={spec.id + evento_id} className='text-center fw-bold align-middle fs-quote ' width={70} >{spec.descrizione}</td>
                          )}
                        </tr>: <></>
                        }
                        
                          {props.concessionari && props.concessionari.map((concessionario)=>
                            concessionario.visible && <tr  key={concessionario.concessionario_codice + evento_id + '1'}>
                              <td 
                                width={90}   
                                className='align-middle fs-quote text-muted pe-3'  
                                align={'right'}
                                style={{borderRight: "1px solid #ccc"}}
                                > {concessionario.concessionario_descrizione}</td>
                              {props.specialita && props.specialita.map((spec)=>{
                                var check_exist = checkExistValue(props.evento.evento_id, competitor.specialita[spec.id], concessionario.concessionario_codice, props.quote)
                                var alert_class = getAlertClass(check_exist, concessionario.concessionario_codice, spec.id)
                                return (
                                  spec.visible && 
                                  <td 
                                    title={(check_exist?  props.quote[props.evento.evento_id][competitor.specialita[spec.id]][concessionario.concessionario_codice]['quota_descrizione']: '')}  
                                    key = {concessionario.concessionario_codice + spec.id + evento_id} 
                                    className={'fs-specialita fs-quote text-center'
                                            + (!props.evento.specialita_attive[String(spec.id)]? ' text-muted font-weight-normal': '')
                                            } 
                                    onClick={()=>{
                                      if(check_exist && props.evento.specialita_attive[String(spec.id)] && props.quote[props.evento.evento_id][competitor.specialita[spec.id]][concessionario.concessionario_codice]['alert'] > 0){
                                        dispatch(IppicaActions.setStateOpenCompetitor(!competitor.open, props.evento, competitor));
                                        } 
                                    }}
                                    style={getStyleTd(check_exist, concessionario.concessionario_codice, spec.id)}

                                    width={IppicaConst.LARGHEZZA_SPECIALITA}>
                                      {CellaQuotaComponent(check_exist, concessionario.concessionario_codice, spec.id, alert_class)}
                                  </td>
                                );
                              }  
                              )}
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      </> }
                      </div>
                  </div>
        </div>
    );
}

export default RowCompetitor;