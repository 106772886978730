import React, { useEffect, useState } from 'react';
import {Button, Table, Row, Col, Dropdown, Modal,Form} from 'react-bootstrap'
import  {useDispatch} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './index.css'
import RowCompetitor from './RowCompetitor';
import * as IppicaActions from '../../redux/ippica/actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { IppicaConst } from '../../constants/ippica';



const RowEvento = (props) => {
    const classNames = require('classnames')
    const evento_id = props.evento.evento_id
    const [loading_quote, setLoadingQuote] = useState(false)
    const dispatch = useDispatch()

    const classColumns = classNames(
      {
        'col-12': props.visualizzazione == IppicaConst.VISUALIZZAZIONE_1_COLONNA,
        'col-6': props.visualizzazione & IppicaConst.VISUALIZZAZIONE_2_COLONNE,
        'col-4': props.visualizzazione & IppicaConst.VISUALIZZAZIONE_3_COLONNE,
      }
    );


    return(
      props.evento.visible && props.evento.enabled && (props.evento.quote_presenti || props.visualizza_quote_presenti) &&
        <>
        <Row  className="bg-dark text-light border-row">
        <Col>
        <Table className="table-borderless bg-dark text-light p-0 mb-0">
        <thead>
              <tr>
                <th>
                <div className='fs-descrizione-evento'>
                  <Dropdown style={{float:"left", marginLeft: '-15px', marginRight: '5px'}}>
                    <Dropdown.Toggle id="dropdown-basic" size='sm' variant={props.evento.errore_quote? 'danger':'dark'}>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick = {()=>{
                          dispatch(IppicaActions.setStateOpenEvento(!props.evento.open, props.evento));
                        }}                      
                      >
                        {props.evento.open ? "Compatta Corsa" : "Espandi Corsa"}
                      </Dropdown.Item>
                      
                      <Dropdown.Item
                        className={props.evento.errore_quote? 'bg-danger': ''}
                        onClick={()=>{
                          dispatch(IppicaActions.getQuoteEvento(props.evento.evento_id, props.concessionari, props.specialita))
                          setLoadingQuote(true)
                          setTimeout(() => {
                            setLoadingQuote(false)
                          }, 1000);
                        }}
                      >
                        Aggiorna Quote
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={()=>{
                          props.attivaGestioneSpecialita(props.evento);
                        }}
                      >
                        Avvisi Specialita
                      </Dropdown.Item>  
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={()=>{
                          dispatch(IppicaActions.setStateEnabledEvent(props.evento, false))
                        //   confirmAlert({
                        //     title: '',
                        //     message: 'Sicuro di voler eliminare questo evento?',
                        //     buttons: [
                        //       {
                        //         label: 'Si',
                        //         onClick: () => dispatch(IppicaActions.setStateEnabledEvent(props.evento, false))

                        //       },
                        //       {
                        //         label: 'No',
                        //       }
                        //     ]
                        //   });
                        // }
                          }
                        }
                      >
                        Rimuovi Corsa
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div style={{float:"left"}}>
                    <span style={{ userSelect: "none", cursor: "pointer"}} onClick = {()=>{
                          dispatch(IppicaActions.setStateOpenEvento(!props.evento.open, props.evento));}} > 
                      {props.evento.descrizione} 
                    </span>
                    <div className='fs-info-evento' style={{ userSelect: "none"}}>
                      {props.evento.data + ' | ' + props.evento.campionato_descrizione}
                    </div>
                  </div>
                </div>
                </th>
                <th>
                  <Table  align={'right'} className='table-borderless mb-0 w-auto' >
                    <tbody>
                      <tr>
                        <td width={150}   className='align-middle fs-quote' align={'right'}>
                        </td>
                        {classColumns == 'col-12' && props.specialita && props.specialita.map((spec)=>
                            spec.visible && <td key={spec.id + evento_id} className='text-center align-middle fs-quote text-center text-light' width={IppicaConst.LARGHEZZA_SPECIALITA} >{spec.descrizione}</td>
                          )}
                      </tr>
                    </tbody>
                  </Table>
                </th>
              </tr>
            </thead>
        </Table>
        </Col>
        </Row>
        {props.evento.open && <div  className="row bg-secondary">
          {props.evento.marcatori && props.evento.marcatori.map((competitor)=>
                <RowCompetitor key={evento_id+competitor.quota_descrizione} alert_abilitati = {props.alert_abilitati} competitor={competitor} evento={props.evento} concessionari={props.concessionari} specialita={props.specialita} quote={props.quote} evento_id={props.evento.evento_id} classColumns={classColumns} tipoVisualizzazione={props.visualizzazione}/>
          )}
          
        </div>
        }
     </>
    );
}

export default RowEvento;