import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import  { useDispatch } from 'react-redux';
import * as IppicaActions from '../../redux/ippica/actions';
import './index.css'


const CestinoEventoCompetitor = (props) => {
    const [open, setOpen] = useState(false)
    const elementi_cancellati = getElementiCancellati(props.eventi)
    const toggle = () =>{setOpen(!open)}

    const dispatch = useDispatch()

    function getElementiCancellati(eventi){
        var result = []
        // gli elementi cancellati sono dei dizionari che hanno come chiave
        // oggetto, tipo, competitor, info
        var elemento = {}
        if(eventi.length){
            eventi.forEach(evento => {
                if(!evento.enabled){
                    elemento = {
                        evento: evento,
                        competitor: null,
                        tipo: 'EVENTO',
                        info: evento.data + ' | ' + evento.campionato_descrizione
                    }
                    result.push(elemento);
                }else{
                    // cerco i marcatori non abilitati
                    evento.marcatori.forEach(competitor => {
                        if(!competitor.enabled){
                            elemento = {
                                evento: evento,
                                competitor: competitor,
                                tipo: 'COMPETITOR',
                                info: evento.data + ' | ' + evento.campionato_descrizione,
                            }
                            result.push(elemento);
                        }
                    })

                }
            })
        }
        return result;
    }

    return (
        <>
        <Button variant="secondary" title='Cestino' onClick={toggle}>
            <i className="bi bi-trash-fill"></i>
            {elementi_cancellati && elementi_cancellati.length>0? 
                <span className="badge rounded-pill badge-notification-mine bg-danger">{elementi_cancellati.length}</span>: <></>
            }
            
        </Button>
        <Modal show={open} onHide={toggle}  size='xl' scrollable='true'>
            <Modal.Header onHide={toggle} closeButton>
                <h4 className="modal-title">Cestino</h4>
            </Modal.Header>
            <Modal.Body >
                <div className='row' >
                    {elementi_cancellati.length>0 ? 
                        elementi_cancellati.map((elemento, index)=>
                        <div key={index} className='col-lg-4'>
                            {elemento.tipo === 'COMPETITOR'? 
                                <div className='text-center' >
                                    <i className="bi bi-person fs-1"></i>
                                    <div style={{minHeight: '90px'}}>
                                        <h6>{elemento.competitor.quota_descrizione}</h6>
                                        <p className='fs-info-evento' style={{ userSelect: "none"}}>
                                            {elemento.evento.descrizione} <br />
                                            {elemento.info}
                                        </p>
                                    </div>
                                    <Button onClick={()=>{
                                            dispatch(IppicaActions.setStateEnabledPlayer(elemento.evento, elemento.competitor, true))
                                    }}>
                                        Ripristina
                                    </Button>
                                </div>
                                : 
                                <div className='text-center'>
                                    <i className="bi bi-people-fill fs-1"></i>
                                    <div style={{minHeight: '90px'}}>
                                        <h6> {elemento.evento.descrizione}</h6>
                                        <p className='fs-info-evento' style={{ userSelect: "none"}}>
                                        {elemento.info}
                                        </p>
                                    </div>
                                    <Button onClick={()=>{
                                            dispatch(IppicaActions.setStateEnabledEvent(elemento.evento, true))
                                    }}>
                                        Ripristina
                                    </Button>
                                </div>
                            }
                            
                        </div>)
                    : 
                        <h4 className='text-center'>Non ci sono elementi da ripristinare</h4>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={toggle}>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );




};


export default CestinoEventoCompetitor;
