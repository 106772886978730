import React, {useState, useEffect  } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button, Form, Dropdown,Offcanvas, DropdownButton, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import * as MarcatoriActions from '../redux/marcatori/actions';
import RowEvento from '../components/marcatori/RowEvento';
import { MarcatoriConst } from '../constants';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {List} from 'react-virtualized';
import { getEventiVisibili, getHeighRowEventoMarcatore } from '../utils/marcatori/odds_function';
import CestinoEventoMarcatore from '../components/marcatori/CestinoEventoMarcatore';


const Marcatori = (props) => {
  window.impostaQuotaValore = impostaQuotaValore  // permette di utilizzare questa funzione da riga console  
  const specialita = useSelector(state => state.Marcatori.specialita);
  const eventi = useSelector(state => state.Marcatori.eventi);
  const concessionari = useSelector(state => state.Marcatori.concessionari);
  const quote = useSelector(state => state.Marcatori.quote);
  const campionati = useSelector(state => state.Marcatori.campionati);
  const distanza = useSelector(state => state.Marcatori.distance);
  const percentuale = useSelector(state => state.Marcatori.percentuale_avg);
  const alert_sound_state = useSelector(state => state.Marcatori.alert_sound_enabled)
  const eye_state = useSelector(state => state.Marcatori.alert_eye);
  const alert_abilitati = useSelector(state => state.Marcatori.alert_attivi)
  const tipo_filtro_data = useSelector(state => state.Marcatori.filtro_data) //0 visualizzazione solo OGGI, 1 OGGI - DOMANI, 2 TUTTI
  const loading = useSelector(state => state.Marcatori.loading);
  const ultimo_aggiornamento = useSelector(state => state.Marcatori.ultimo_aggiornamento);
  const timeout_cancellazione_eventi = useSelector(state => state.Marcatori.timeout_cancellazione_eventi);
  const limite_quota_media = useSelector(state => state.Marcatori.limite_quota_media);
  const [tipo_ordinamento, setTipoOrdinamento] = useState(0); //0 ordinamento per data, 1 ordinamento per nome campionato
  const [tipo_visualizzazione, setTipoVisualizzaione] = useState(0); //0 visualizzazione singola colonna, 1 doppia colonna, 2 tripla colonna
  const [filtro_testo, setFiltroTesto] = useState('')
  const [tipo_filtro_form, setTipoFiltroForm] = useState(1) // 0 evento, 1 marcatore
  const [visibilita_marcatori, setVibilitaMarcatori] = useState(false)
  const [visibilita_eventi, setVibilitaEventi] = useState(true)
  const [openCampionati, setOpenCampionati] = useState(true)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [sizeList, setSizeList] = useState([window.innerWidth, window.innerHeight])
  const isRowLoaded = ({index}) =>  index < eventi.length;
  var list = null
  const [timeoutDeleteEvento, setTimeoutDeleteEvento] = useState(null)
  
  const dispatch = useDispatch()
  const [showFiltri, setShowFiltri] = useState(false);

  const handleCloseFiltri = () => setShowFiltri(false);
  const handleShowFiltri = () => setShowFiltri(true);

  // definito un listener che ogni N millisecondi controlla ed elimina dalla visualizzazione gli eventi già iniziati
  
  // ************* FUNZIONALITA DISATTIVATA ************
  /*const intervalDeleteEvents = setInterval(() => {
    dispatch(MarcatoriActions.deleteStartedEvents())
  }, ServersConst.MILLISECOND_WAIT);*/

  window.onresize = function(event) {
    setSizeList([window.innerWidth, window.innerHeight])
  };
  
  // funzione che viene chiamata quando si vuole cercare une vento specifico tramite una stringa
  const onSubmitSearchEvento = (e) => {
    e.preventDefault();
    if(tipo_filtro_form === 0){
      dispatch(MarcatoriActions.setFiltroEvento(filtro_testo))
    }else if(tipo_filtro_form === 1){
      dispatch(MarcatoriActions.setFiltroMarcatore(filtro_testo))
    }     
  };

  const getDescrizioneAvviso = (alert) => {
    // funzione che in base alla chiave presente nella struttura "alert_abiltati" ritorna la descrizione dell'avviso
    // da fornire nella visualizzazione delle impostazioni, gli alert per ora sono
    // 'BF' -> 'BETFAIR'
    // 'AVG' -> 'Quota Media'
    if (alert === 'BF'){
      return 'BETFAIR';
    }else if( alert === 'AVG'){
      return 'Quota Media';
    }
  }

  // funzione chiamata da console che permette di inserire una quota
  function impostaQuotaValore(evento_id, quota_id, concessionario_codice, valore, valore_2=0){
    var esiste = false
    eventi.forEach((evento)=>
      {
        if(evento_id === evento.evento_id){
          esiste = true;
          return;
        }
      })
    if(!esiste)
    {
      if(valore > 1){
        // caricarmi l'evento con evento id nello stato eventi
        dispatch(MarcatoriActions.getEvento(evento_id, quota_id, concessionario_codice, valore, valore_2))
        // carico anche le quote dell'evento se esso non esiste
        dispatch(MarcatoriActions.getQuoteEvento(evento_id, concessionari, specialita))
      }
    } else {
      dispatch(MarcatoriActions.setQuota(evento_id, quota_id, concessionario_codice, valore, valore_2));
    }
    
}

  // permette di caricare le quote dei singoli eventi ID tramite un array di eventi ID
  // function aggiungiQuoteEventi(listaEventiId)
  // {
  //   listaEventiId.forEach((eventoId)=>{
  //     dispatch(MarcatoriActions.getQuoteEvento(eventoId, concessionari, specialita))
  //   })
  // }
  
  // carica tutte le quote per gli eventi che sono stati caricati all'avvio della pagina
  function impostaQuoteEventi()
  {
    eventi.forEach((evento)=>
    {
      dispatch(MarcatoriActions.getQuoteEvento(evento.evento_id, concessionari, specialita))
    })
  }

  // carico all'avvio della pagina tutti gli eventi i concessionari e le specialita
  useEffect(() => 
  {
    var token = params.get('tok')
    dispatch(MarcatoriActions.setToken(token));
    dispatch(MarcatoriActions.getConcessionari());
    dispatch(MarcatoriActions.getSpecialita());
    dispatch(MarcatoriActions.getEventi());
  }, [])

  // quando saranno caricati gli eventi allora caricherò tutte le quote
  useEffect(() => 
  {
    impostaQuoteEventi()
    dispatch(MarcatoriActions.setCampionati(eventi))
  }, [loading]);

  // quando verrà cambiato il timeout allora verrà sovrascritto il vecchio setTimeout con uno nuovo che attenderà
  // il nuovo timeout
  useEffect(() => 
  {
    if(timeout_cancellazione_eventi !== null){
      clearTimeout(timeoutDeleteEvento)
      setTimeoutDeleteEvento(setTimeout(() => {
        dispatch(MarcatoriActions.deleteStartedEvents())
      }, timeout_cancellazione_eventi))  
    }
  }, [timeout_cancellazione_eventi]);

  const nomeFiltroDataAttivo = () => {
    if(tipo_filtro_data === MarcatoriConst.EVENTI_OGGI){
      return 'OGGI';
    }else if(tipo_filtro_data === MarcatoriConst.EVENTI_OGGI_DOMANI){
      return 'OGGI - DOMANI'
    }else if (tipo_filtro_data === MarcatoriConst.EVENTI_TUTTI){
      return 'TUTTI'
    }
  }


  const nomeTipologiaVisualizzazione = () => {
    if(tipo_visualizzazione === MarcatoriConst.VISUALIZZAZIONE_1_COLONNA){
      return '1 COLONNA';
    }else if(tipo_visualizzazione === MarcatoriConst.VISUALIZZAZIONE_2_COLONNE){
      return '2 COLONNE'
    }else if (tipo_visualizzazione === MarcatoriConst.VISUALIZZAZIONE_3_COLONNE){
      return '3 COLONNE'
    }
  }

  const nomeTipoOrdinamentoAttivo = () => {
    if(tipo_ordinamento === MarcatoriConst.TIPO_ORDINAMENTO_PER_DATA){
      return 'ORDINAMENTO DATA';
    }else if(tipo_ordinamento === MarcatoriConst.TIPO_ORDINAMENTO_PER_CAMPIONATO){
      return 'ORDINAMENTO CAMPIONATO';
    }
  }


  return (
    <>
    {loading ? 
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>:
    <div className='' >
    {eventi.length>0? 
      <>
      <div id="mySidenav" className="sidenav"  style={(openCampionati? { width: "250px" }: {})} >
        <div className="text-light text-center" style={{fontSize: '8px'}}>
          <p>Ultimo Aggiornamento: {ultimo_aggiornamento && new Date(ultimo_aggiornamento).toLocaleString("it-IT",{year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'} )}</p>
        </div>
        <div hidden = {!openCampionati} className='bold title my-2'>
            <Form.Check
                href="#"
                className='input-checkbox'
                type={'checkbox'}
                defaultChecked={true}
                onChange={(event)=>{
                  dispatch(MarcatoriActions.changeVisualizationEventiAll(event.target.checked))
                }}
                label='CAMPIONATI'
                title='Cambia la visualizzazione dei campionati'
            />
        </div>
      {campionati && campionati.map((campionato)=>
        <div key={campionato.campionato_id} hidden = {!openCampionati} style={{textWrap: "nowrap"}} className='bold text-light mb-1'>
          <Form.Check
            href="#"
            className='input-checkbox'
            type={'checkbox'}
            checked={campionato.visible}
            onChange={()=>{
              dispatch(MarcatoriActions.changeVisualizationEventi(campionato))             
            }}
            key={campionato.campionato_descrizione}
            name={campionato.campionato_descrizione}
            label={campionato.campionato_descrizione}
            value={campionato.campionato_descrizione}
          />
          
        </div>
        
      )}
      <div className="text-light text-center my-2">
          <p>Eventi visualizzati: {getEventiVisibili(eventi).length}<br />
            <span style={{fontSize:"9px"}}>versione {MarcatoriConst.VERSIONE}</span>
          </p>
          
      </div>
    </div>
      <nav className="navbar navbar-expand-lg bg-light sticky-top transitionmaincontent"  style={(openCampionati? { marginLeft: "250px" }: {})}>
          <div className="container-fluid" >
              <button className="navbar-toggler" type="button" data-mdb-toggle="collapse" aria-label="Toggle navigation"
              aria-controls="navbarSupportedContent" aria-expanded="false" data-mdb-target="#navbarSupportedContent" >
                  <i className="fas fa-bars"></i>
              </button>
              <div className="collapse navbar-collapse navbarsetmargin" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  
                  <Button 
                        variant="secondary"
                        className="bi bi-list mx-0"
                        onClick={()=>{    
                            setOpenCampionati(!openCampionati)
                          }}
                        title={(openCampionati? 'Nascondi campionati': 'Mostra campionati')}
                  ></Button>
                    <Form className='d-flex ms-4' onSubmit={onSubmitSearchEvento}
                      >
                      <InputGroup>
                        <DropdownButton
                          variant="outline-secondary"
                          title={(tipo_filtro_form===0? 'Evento': 'Marcatore')}
                          id="input-group-dropdown-2"
                          align="end"
                          className='w-100'
                          size='sm'
                        >
                          <Dropdown.Item onClick={()=>{
                             setTipoFiltroForm(0);
                          }}>
                            Evento
                          </Dropdown.Item>
                          <Dropdown.Item onClick={()=>{
                             setTipoFiltroForm(1);
                          }}> 
                            Marcatore
                          </Dropdown.Item>
                        </DropdownButton>
                        <Form.Control
                            type="text"
                            id="inputEventDescription"
                            placeholder={(tipo_filtro_form===0? 'Cerca evento': 'Cerca marcatore')}
                            value={filtro_testo}
                            onChange={(event)=>{
                              setFiltroTesto(event.target.value)
                            }
                            }
                          />
                        </InputGroup>
                        <Button variant="secondary" className="mx-2 bi bi-search" type="submit" title='Cerca evento'></Button>
                    </Form>
                  <CestinoEventoMarcatore eventi={eventi}/>
                  </ul>
                  <ul className="navbar-nav d-flex flex-row me-1">
                  <Dropdown className='mx-2'>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic" title='Tipi Ordinamento'>
                        {nomeTipoOrdinamentoAttivo()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                        className={(tipo_ordinamento === MarcatoriConst.TIPO_ORDINAMENTO_PER_DATA ? 'bg-secondary bg-gradient': '')}
 
                        onClick = {()=>{
                            dispatch(MarcatoriActions.changeTipoOrdinamento(MarcatoriConst.TIPO_ORDINAMENTO_PER_DATA))
                            setTipoOrdinamento(MarcatoriConst.TIPO_ORDINAMENTO_PER_DATA)
                          }} 
                        >
                          ORDINAMENTO DATA
                        </Dropdown.Item>

                        <Dropdown.Item
                          className={(tipo_ordinamento === MarcatoriConst.TIPO_ORDINAMENTO_PER_CAMPIONATO ? 'bg-secondary bg-gradient': '')}
                          onClick = {()=>{
                            dispatch(MarcatoriActions.changeTipoOrdinamento(MarcatoriConst.TIPO_ORDINAMENTO_PER_CAMPIONATO))

                            setTipoOrdinamento(MarcatoriConst.TIPO_ORDINAMENTO_PER_CAMPIONATO)
                          }}
                        >
                          ORDINAMENTO CAMPIONATO
                        </Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className='mx-2'>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic" title='Lista filtri per data'>
                        {nomeFiltroDataAttivo()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                        className={(tipo_filtro_data === MarcatoriConst.EVENTI_OGGI ? 'bg-secondary bg-gradient': '')}
 
                        onClick = {()=>{
                            dispatch(MarcatoriActions.setTipoFiltroData(MarcatoriConst.EVENTI_OGGI))
                          }} 
                        >
                          OGGI
                        </Dropdown.Item>

                        <Dropdown.Item
                          className={(tipo_filtro_data === MarcatoriConst.EVENTI_OGGI_DOMANI ? 'bg-secondary bg-gradient': '')}
                          onClick = {()=>{
                            dispatch(MarcatoriActions.setTipoFiltroData(MarcatoriConst.EVENTI_OGGI_DOMANI))
                          }}
                        >
                          OGGI - DOMANI
                        </Dropdown.Item>

                        <Dropdown.Item
                        className={(tipo_filtro_data === MarcatoriConst.EVENTI_TUTTI ? 'bg-secondary bg-gradient': '')}
                        onClick = {()=>{
                          dispatch(MarcatoriActions.setTipoFiltroData(MarcatoriConst.EVENTI_TUTTI))
                          }}
                        >
                          TUTTI
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='mx-2'>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic" title='Lista tipologie visualizzazione'>
                        {nomeTipologiaVisualizzazione()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item 
                        className={(tipo_visualizzazione === MarcatoriConst.VISUALIZZAZIONE_1_COLONNA ? 'bg-secondary bg-gradient': '')}
                        onClick = {()=>{
                          setTipoVisualizzaione(MarcatoriConst.VISUALIZZAZIONE_1_COLONNA)
                          if(list)
                            list.recomputeRowHeights(0)
                          }} 
                        >
                          1 Colonna
                          <i className="mx-1 bi bi-square"></i>
                        </Dropdown.Item>

                        <Dropdown.Item
                        className={(tipo_visualizzazione === MarcatoriConst.VISUALIZZAZIONE_2_COLONNE ? 'bg-secondary bg-gradient': '')}
                        onClick = {()=>{
                          setTipoVisualizzaione(MarcatoriConst.VISUALIZZAZIONE_2_COLONNE)
                          if(list)
                            list.recomputeRowHeights(0)

                          }}
                        >
                          2 Colonne
                          <i className="mx-1 bi bi-layout-split"></i>
                        </Dropdown.Item>

                        <Dropdown.Item 
                        className={(tipo_visualizzazione === MarcatoriConst.VISUALIZZAZIONE_3_COLONNE ? 'bg-secondary bg-gradient': '')}
                        onClick = {()=>{
                          setTipoVisualizzaione(MarcatoriConst.VISUALIZZAZIONE_3_COLONNE)
                          if(list)
                            list.recomputeRowHeights(0)

                          }}
                        >
                          3 Colonne
                          <i className="mx-1 bi bi-layout-three-columns"></i>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                      <p className='divisore-icone'></p>

                      <Button 
                          className="bi bi-arrow-clockwise btn btn-light"
                          onClick={()=>{
                            confirmAlert({
                              title: '',
                              message: 'Sicuro di voler ricaricare la pagina?',
                              buttons: [
                                {
                                  label: 'Si',
                                  onClick: () => window.location.reload(false)
                                },
                                {
                                  label: 'No',
                                }
                              ]
                            }
                          );
                        }
                      }
                      title='Ricarica la pagina'
                      ></Button>
                      <p className='divisore-icone'></p>
                      <Button
                        className={'btn btn-light ' + ((eye_state) ? "bi bi-eye-slash bg-secondary text-white" : "bi bi-eye")}
                        onClick = {()=>{
                          dispatch(MarcatoriActions.setEyeState(!eye_state))  
                          }}
                          title={(eye_state? 'Visualizza tutto': 'Visualizza solo Alert')}    
                      >
                        
                      </Button>
                      <p className='divisore-icone'></p>
                      <Button
                        className={'btn btn-light ' + ((visibilita_marcatori)? "bi bi-arrows-collapse": "bi bi-arrows-expand")}
                        onClick = {()=>{
                            
                            dispatch(MarcatoriActions.allSetStateOpenMarcatore(!visibilita_marcatori))
                            setVibilitaMarcatori(!visibilita_marcatori)
                          }}
                        title={(visibilita_marcatori? 'Chiudi tutti i marcatori': 'Apri tutti i marcatori')}
                      >
                      </Button>
                      <Button 
                        className= {'btn btn-light ' + ((visibilita_eventi) ? "bi bi-arrows-angle-contract" : "bi bi-arrows-angle-expand")}
                        style={{float: "right"}}
                        onClick = {()=>{
                            dispatch(MarcatoriActions.allSetStateOpenEvento(!visibilita_eventi))
                            setVibilitaEventi(!visibilita_eventi)
                          }}
                        title={(visibilita_eventi? 'Chiudi tutti gli eventi': 'Apri tutti gli eventi')}
                      >
                      </Button>
                      <p className='divisore-icone'></p>
                      <Button 
                        className= {'btn btn-light ' + ((alert_sound_state) ? "bi bi-volume-up-fill" : "bi bi-volume-mute-fill")}
                        style={{float: "right"}}
                        onClick = {()=>{
                            dispatch(MarcatoriActions.changeAlertSoundState())
                          }}
                        title={(alert_sound_state? 'Disabilita il suono': 'Abilita il suono')}    
                      >

                      </Button>
                    
                    

                    <Button title='Impostazioni' variant="light" onClick={handleShowFiltri} className='bi bi-gear-fill'>
                    </Button>
                    <Offcanvas show={showFiltri} onHide={handleCloseFiltri} scroll={true} backdrop={true} placement={'end'}>
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title style={{width: '355px', textAlign: 'center'}}>
                          IMPOSTAZIONI
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                      <div className='container'>
                        <div className='box-impostazioni'>
                          <h6 className='title-box-impostazioni'>Avvisi</h6>
                          {Object.keys(alert_abilitati).map((alert)=>
                            <div key={alert} className='row mb-2'>
                              <div className='col-6'>
                                <Form.Check
                                  type={'checkbox'}
                                  checked={alert_abilitati[alert]['abilitato']}
                                  onChange={()=>{
                                    var temp_alert = {...alert_abilitati}
                                    temp_alert[alert]['abilitato'] = !temp_alert[alert]['abilitato']
                                    dispatch(MarcatoriActions.setAlertAbilitati(temp_alert))
                                  }}
                                  style={{marginTop: '0.25rem'}}
                                  key={alert_abilitati[alert]['descrizione']}
                                  name={alert_abilitati[alert]['descrizione']}
                                  label={getDescrizioneAvviso(alert)}
                                  value={alert_abilitati[alert]['abilitato']}
                                >
                                </Form.Check>
                              </div> 
                              <div className='col-6'>
                                  {alert && alert === 'BF' &&
                                    <div className='row'>
                                      <div className='col-10 p-0'>
                                        <div className='row'>
                                          <div className="col-2" style={{alignSelf:'center'}}>
                                            #
                                          </div>
                                          <div className='col-10'>
                                            <Form.Control
                                              type="number"
                                              id="inputDistance"
                                              size='sm'
                                              step={0.01}
                                              max={1}
                                              min={0}
                                              disabled={!alert_abilitati[alert]['abilitato']}
                                              onChange={(event)=>{
                                                dispatch(MarcatoriActions.setDistance(event.target.value))
                                                dispatch(MarcatoriActions.checkAlertDistance());
                                                }}
                                              value = {distanza}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <OverlayTrigger placement='left' overlay={(props) => (
                                        <Tooltip {...props}>
                                          Aggiunge il valore impostato alle quote del concessionario. 
                                          Se il risultato è MAGGIORE o UGUALE alla quota di BETFAIR sarà visualizzato un <font color="red">avviso</font> 
                                        </Tooltip>
                                      )}>
                                        <div className='col-2 bi bi-info-circle-fill p-1' style={{alignSelf:'center'}}>
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  }
                                  {alert && alert === 'AVG' &&
                                    <div className='row'>
                                      <div className='col-10 p-0'>
                                        <div className='row mb-1'>
                                          <div className="col-2" style={{alignSelf:'center'}}>
                                            %
                                          </div>
                                          <div className='col-10'>
                                            <Form.Control
                                              type="number"
                                              id="inputPercentuale"
                                              size='sm'
                                              disabled={!alert_abilitati[alert]['abilitato']}
                                              step={1}
                                              max={999}
                                              min={0}
                                              onChange={(event)=>{
                                                dispatch(MarcatoriActions.setPercentuale(event.target.value))
                                                dispatch(MarcatoriActions.checkQuotaMedia())
                                              }}
                                              value = {percentuale}
                                            />
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <div className="col-2 p-0" style={{alignSelf:'center'}}>
                                            max
                                          </div>
                                          <div className='col-10'>
                                              <Form.Control
                                              type="number"
                                              id="inputPercentuale2"
                                              size='sm'
                                              step={0.01}
                                              max={999}
                                              min={0}
                                              disabled={!alert_abilitati[alert]['abilitato']}
                                              onChange={(event)=>{
                                                dispatch(MarcatoriActions.setLimiteQuotaMedia(event.target.value))
                                                dispatch(MarcatoriActions.checkQuotaMedia())
                                              }}
                                              value = {limite_quota_media}
                                            />
                                          </div>
                                        </div>
                                        
                                      </div>
                                      <OverlayTrigger placement='left' overlay={(props) => (
                                        <Tooltip {...props}>
                                          Sarà visualizzato un <font color="yellow">avviso</font> per le quote del concessionario MAGGIORI della quota media, di almeno la percentuale (<font color="yellow">%</font>) indicata.
                                          Sarà possibile definire un limite massimo (<font color="yellow">max</font>) per la quota del concessionario.
                                        </Tooltip>
                                      )}>
                                        <div className='col-2 bi bi-info-circle-fill p-1' style={{alignSelf:'center'}}>
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  }
                              </div>
                            </div>               
                          )}
                        </div>
                        <div className='box-impostazioni'>
                          <h6 className='title-box-impostazioni'>Concessionari Visualizzati</h6>
                          <div className='row container'>
                            {concessionari && concessionari.map((concessionario)=>{
                                if(concessionario.concessionario_codice !== MarcatoriConst.CONCESSIONARIO_VIRTUALE){
                                    return (
                                        <Form.Check
                                          type={'checkbox'}
                                          checked = {concessionario.visible}
                                          onChange = {()=>{
                                            dispatch(MarcatoriActions.changeVisualizationConcessionari(concessionario))
                                            // dispatch(MarcatoriActions.checkQuotaMedia());
                                            }}
                                          key={concessionario.concessionario_codice}
                                          name={concessionario.concessionario_descrizione}
                                          label={concessionario.concessionario_descrizione}
                                          value={concessionario.concessionario_descrizione}
                                          className='col-6'
                                        />
                                    )
                                }
                                return null
                              }
                            )}
                            </div>
                        </div>
                        <div className='box-impostazioni'>
                          <h6 className='title-box-impostazioni'>Concessionari Quota Media</h6>
                          <div className='row container'>
                            {concessionari && concessionari.map((concessionario)=>{
                                if(concessionario.concessionario_codice !== MarcatoriConst.CONCESSIONARIO_VIRTUALE && !MarcatoriConst.CONFRONTO.includes(concessionario.concessionario_codice)){
                                    return (
                                        <Form.Check
                                          type={'checkbox'}
                                          checked = {concessionario.valuta_quota_media}
                                          onChange = {()=>{
                                            dispatch(MarcatoriActions.changeValutaQuotaMediaConcessionario(concessionario))
                                            dispatch(MarcatoriActions.checkQuotaMedia());
                                            }}
                                          key={concessionario.concessionario_codice}
                                          name={concessionario.concessionario_descrizione}
                                          label={concessionario.concessionario_descrizione}
                                          value={concessionario.concessionario_descrizione}
                                          className='col-6'
                                        />
                                    )
                                }
                                return null
                              }
                            )}
                            </div>
                        </div>
                        <div className='box-impostazioni'>
                          <h6 className='title-box-impostazioni'>Specialità</h6>
                          <div className='row'>
                          {specialita && specialita.map((spec)=>
                            <div key={spec.id} className='col-6' >
                              <Form.Check
                                type={'checkbox'}
                                checked={spec.visible}
                                onChange={()=>{
                                  dispatch(MarcatoriActions.changeVisualizationSpecialita(spec))
                                  if(eye_state){
                                    dispatch(MarcatoriActions.setEyeState(eye_state))
                                  }
                                  }}
                                key={spec.descrizione}
                                name={spec.descrizione}
                                label={spec.descrizione}
                                value={spec.descrizione}
                              />
                            </div>
                          )}
                          </div>
                        </div>
                        <div className='my-4 text-center'>
                          <Button
                            onClick={()=>{
                              dispatch(MarcatoriActions.saveImpostazioni())
                              // permette di chiudere la lista dei filtri
                              handleCloseFiltri()
                            }}>
                              Salva Impostazioni
                          </Button>
                        </div>
                      </div>
                      </Offcanvas.Body>
                    </Offcanvas>



                     
                  </ul>
              </div>
          </div>
      </nav>
      <List
        style={(openCampionati? { marginLeft: "250px"}: {})} 
        ref={bindListRef}
        width= {sizeList[0]-(openCampionati? 250: 0)}
        height={sizeList[1]-58}
        rowCount={eventi.length}
        rowHeight={rowheight}
        rowRenderer={rowRenderer}
        overscanRowCount={2}
      />
      {/* {eventi && eventi.map((evento, index)=>
          <RowEvento key={evento.evento_id} alert_abilitati = {alert_abilitati} eventoScrollVisible={index < 5} eventiVisibility={visibilita_eventi} marcatoriVisibility={visibilita_marcatori} evento={evento} specialita={specialita} concessionari={concessionari} quote={quote} distanza={distanza} visualizzazione={tipo_visualizzazione}/>
        )
      } */}
      </>: 
      <>
        <div >
          <h2 className='container-empty-page'><i className="bi bi-x-circle-fill"></i> Nessun evento presente</h2>
        </div>
      </>
    }
    
    </div>
    }
    </>);

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
    }) {
    // if(isScrolling) {
    //   return (
    //     <div
    //       key={key}
    //       style={style}>
    //       ...
    //     </div>
    //   );
    // }
    return (
      <div style={style} key={key} className='container-fluid' >
        <RowEvento 
          alert_abilitati = {alert_abilitati} 
          eventoScrollVisible={true} 
          eventiVisibility={visibilita_eventi} 
          marcatoriVisibility={visibilita_marcatori} 
          evento={eventi[index]} 
          specialita={specialita} 
          concessionari={concessionari} 
          quote={quote} 
          distanza={distanza} 
          visualizzazione={tipo_visualizzazione}
          />
      </div>
      );      
      // return (
      //     isScrolling? '...' : <RowEvento style={style} key={eventi[index].evento_id} alert_abilitati = {alert_abilitati} eventoScrollVisible={index < 5} eventiVisibility={visibilita_eventi} marcatoriVisibility={visibilita_marcatori} evento={eventi[index]} specialita={specialita} concessionari={concessionari} quote={quote} distanza={distanza} visualizzazione={tipo_visualizzazione}/>
      // );
    }
  
  function rowheight({ index }){
    if (!isRowLoaded({index})){
      return 0.8*window.innerWidth;
    }else{      
      return getHeighRowEventoMarcatore(eventi[index], tipo_visualizzazione, concessionari)
    }
  }
  
  function bindListRef (ref) {
      dispatch(MarcatoriActions.setRefList(ref));
      list = ref
  };


  
  };
  
  export default Marcatori;

  