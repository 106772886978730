import React, {useState, useEffect  } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as IppicaActions from '../redux/ippica/actions';
import {Button, Form, Dropdown,Offcanvas, DropdownButton, InputGroup, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import RowEvento from '../components/ippica/RowEvento';
import { IppicaConst } from '../constants/ippica';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {List} from 'react-virtualized';
import { getEventiVisibili, getHeighRowEventoCompetitor} from '../utils/ippica/odds_function';
import CestinoEventoCompetitor from '../components/ippica/CestinoEventoCompetitor';


const Ippica = () => {
  window.impostaQuotaValore = impostaQuotaValore  // permette di utilizzare questa funzione da riga console  
  const specialita = useSelector(state => state.Ippica.specialita);
  const eventi = useSelector(state => state.Ippica.eventi);
  const concessionari = useSelector(state => state.Ippica.concessionari);
  const quote = useSelector(state => state.Ippica.quote);
  const campionati = useSelector(state => state.Ippica.campionati);
  const distanza_banca = useSelector(state => state.Ippica.distance_banca);
  const distanza_punta = useSelector(state => state.Ippica.distance_punta);
  const alert_sound_state = useSelector(state => state.Ippica.alert_sound_enabled)
  const eye_state = useSelector(state => state.Ippica.alert_eye);
  const alert_abilitati = useSelector(state => state.Ippica.alert_attivi)
  const tipo_filtro_data = useSelector(state => state.Ippica.filtro_data) //0 visualizzazione solo OGGI, 1 OGGI - DOMANI, 2 TUTTI
  const loading = useSelector(state => state.Ippica.loading);
  const ultimo_aggiornamento = useSelector(state => state.Ippica.ultimo_aggiornamento);
  const visualizza_quote_presenti = useSelector(state => state.Ippica.visualizza_quote_presenti);
  const [tipo_visualizzazione, setTipoVisualizzaione] = useState(0); //0 visualizzazione singola colonna, 1 doppia colonna, 2 tripla colonna
  const [tipo_ordinamento, setTipoOrdinamento] = useState(0); //0 ordinamento per data, 1 ordinamento per nome campo
  const [filtro_testo, setFiltroTesto] = useState('')
  const [tipo_filtro_form, setTipoFiltroForm] = useState(1) // 0 evento, 1 competitor
  const [visibilita_competitors, setVibilitaCompetitors] = useState(true)
  const [visibilita_eventi, setVibilitaEventi] = useState(true)
  const [openCampionati, setOpenCampionati] = useState(true)
  const [open_gestisci_specialita, setOpenGestisciSpecialita] = useState(false)
  const [evento_specialita, setEventoSpecialita] = useState(null)
  const [campionato_specialita, setCampionatoSpecialita] = useState(null)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [sizeList, setSizeList] = useState([window.innerWidth, window.innerHeight])
  const isRowLoaded = ({index}) =>  index < eventi.length;
  var list = null
  
  
  const dispatch = useDispatch()
  const [showFiltri, setShowFiltri] = useState(false);

  const handleCloseFiltri = () => setShowFiltri(false);
  const handleShowFiltri = () => setShowFiltri(true);

  // definito un listener che ogni N millisecondi controlla ed elimina dalla visualizzazione gli eventi già iniziati
  
  // ************* FUNZIONALITA DISATTIVATA ************
  /*const intervalDeleteEvents = setInterval(() => {
    dispatch(IppicaActions.deleteStartedEvents())
  }, ServersConst.MILLISECOND_WAIT);*/

  window.onresize = function(event) {
    setSizeList([window.innerWidth, window.innerHeight])
  };
  
  // funzione che viene chiamata quando si vuole cercare une vento specifico tramite una stringa
  const onSubmitSearchEvento = (e) => {
    e.preventDefault();
    if(tipo_filtro_form == 0){
      dispatch(IppicaActions.setFiltroEvento(filtro_testo))
    }else if(tipo_filtro_form == 1){
      dispatch(IppicaActions.setFiltroCompetitor(filtro_testo))
    }     
  };

  // funzione chiamata da console che permette di inserire una quota
  function impostaQuotaValore(evento_id, quota_id, concessionario_codice, valore, valore_2 = 0){
    if(loading) return;
    var esiste = false
    eventi.forEach((evento)=>
      {
        if(evento_id == evento.evento_id){
          esiste = true;
          return;
        }
      })
    if(!esiste)
    {
      // caricarmi l'evento con evento id nello stato eventi
      if(valore > 1){
        dispatch(IppicaActions.getEvento(evento_id, quota_id, concessionario_codice, valore, valore_2));
        dispatch(IppicaActions.getQuoteEvento(evento_id, concessionari, specialita));
      }
    } else {
      dispatch(IppicaActions.setQuota(evento_id, quota_id, concessionario_codice, valore, valore_2));
    }
    
}

  // permette di caricare le quote dei singoli eventi ID tramite un array di eventi ID
  function aggiungiQuoteEventi(listaEventiId)
  {
    listaEventiId.forEach((eventoId)=>{
      dispatch(IppicaActions.getQuoteEvento(eventoId, concessionari, specialita))
    })
  }
  
  // carica tutte le quote per gli eventi che sono stati caricati all'avvio della pagina
  function impostaQuoteEventi()
  {
    eventi.forEach((evento)=>
    {
      dispatch(IppicaActions.getQuoteEvento(evento.evento_id, concessionari, specialita))
    })
  }

  // carico all'avvio della pagina tutti gli eventi i concessionari e le specialita
  useEffect(() => 
  {
    var token = params.get('tok')
    dispatch(IppicaActions.setToken(token));
    dispatch(IppicaActions.getSpecialita());
    dispatch(IppicaActions.getConcessionari());
  }, [])

  // quando ho disponibile la lista dei concessionari e delle specialita, allora carico gli eventi se non li ho già caricati
  useEffect(() =>
  {
    if(loading && concessionari.length && specialita.length) {
      dispatch(IppicaActions.getEventi());
    }
  }, [specialita, concessionari]);

  // quando saranno caricati gli eventi allora caricherò tutte le quote
  useEffect(() => 
  {
    if(!loading) {
      impostaQuoteEventi();
      dispatch(IppicaActions.setCampionati(eventi))
    }
  }, [loading]);


  const nomeFiltroDataAttivo = () => {
    if(tipo_filtro_data == IppicaConst.EVENTI_OGGI){
      return 'OGGI';
    }else if(tipo_filtro_data == IppicaConst.EVENTI_OGGI_DOMANI){
      return 'OGGI - DOMANI'
    }else if (tipo_filtro_data == IppicaConst.EVENTI_TUTTI){
      return 'TUTTI'
    }
  }


  const nomeTipologiaVisualizzazione = () => {
    if(tipo_visualizzazione == IppicaConst.VISUALIZZAZIONE_1_COLONNA){
      return '1 COLONNA';
    }else if(tipo_visualizzazione == IppicaConst.VISUALIZZAZIONE_2_COLONNE){
      return '2 COLONNE'
    }else if (tipo_visualizzazione == IppicaConst.VISUALIZZAZIONE_3_COLONNE){
      return '3 COLONNE'
    }
  }

  const nomeTipoOrdinamentoAttivo = () => {
    if(tipo_ordinamento == IppicaConst.TIPO_ORDINAMENTO_PER_DATA){
      return 'ORDINAMENTO DATA';
    }else if(tipo_ordinamento == IppicaConst.TIPO_ORDINAMENTO_PER_CAMPIONATO){
      return 'ORDINAMENTO CAMPI';
    }
  }

  const nomeVisualizzazioneQuotePresentiAttiva = () => {
    return (visualizza_quote_presenti? 'Tutte': 'Solo quotate')
  }



  return (
    <>
    {loading ? 
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>:
    <div className='' >
    {eventi.length>0? 
      <>
      <div id="mySidenav" className="sidenav"  style={(openCampionati? { width: "250px" }: {})} >
        <div className="text-light text-center" style={{fontSize: '8px'}}>
          <p>Aggiornato al: {ultimo_aggiornamento && new Date(ultimo_aggiornamento).toLocaleString("it-IT",{year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'} )}</p>
        </div>
        <div hidden = {!openCampionati} className='bold title my-2'>
          <Form.Check
              href="#"
              className='input-checkbox'
              type={'checkbox'}
              defaultChecked={true}
              onChange={(event)=>{
                dispatch(IppicaActions.changeVisualizationEventiAll(event.target.checked))
              }}
              label='CAMPI'
              title='Cambia la visualizzazione dei campi'
          />
        </div>
      {campionati && campionati.map((campionato)=>
        <div key={campionato.campionato_id} hidden = {!openCampionati} className='row bold text-light mb-1'>
          <div className='col-10'>
            <Form.Check
              href="#"
              className='input-checkbox'
              type={'checkbox'}
              checked={campionato.visible}
              onChange={()=>{
                dispatch(IppicaActions.changeVisualizationEventi(campionato))
              }}
              key={campionato.campionato_descrizione}
              name={campionato.campionato_descrizione}
              label={campionato.campionato_descrizione}
              value={campionato.campionato_descrizione}
            />
          </div>
          <div className='col-2' style={{cursor: 'pointer'}}>
            <span
              className='bg-dark bi bi-card-checklist'
              onClick={()=>{
                  modalGestisciSpecialitaCampionato(campionato)             
              }}
            >
            </span>
          </div>

          
        </div>
      )}
      <div className="text-light text-center my-2">
          <p>Eventi visualizzati: {getEventiVisibili(eventi, visualizza_quote_presenti).length}<br />
          <span style={{fontSize: '9px'}}>versione {IppicaConst.VERSIONE}</span></p>
      </div>
    </div>
      <nav className="navbar navbar-expand-lg bg-light sticky-top transitionmaincontent"  style={(openCampionati? { marginLeft: "250px" }: {})}>
          <div className="container-fluid" >
              <button className="navbar-toggler" type="button" data-mdb-toggle="collapse" aria-label="Toggle navigation"
              aria-controls="navbarSupportedContent" aria-expanded="false" data-mdb-target="#navbarSupportedContent" >
                  <i className="fas fa-bars"></i>
              </button>
              <div className="collapse navbar-collapse navbarsetmargin" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  
                  <Button 
                        variant="secondary"
                        className="bi bi-list mx-0"
                        onClick={()=>{    
                            setOpenCampionati(!openCampionati)
                          }}
                        title={(openCampionati? 'Nascondi campi': 'Mostra campi')}
                  ></Button>
                    <Form className='d-flex ms-4' onSubmit={onSubmitSearchEvento}
                      >
                      <InputGroup>
                        <DropdownButton
                          variant="outline-secondary"
                          title={(tipo_filtro_form==0? 'Corsa': 'Cavallo')}
                          id="input-group-dropdown-2"
                          align="end"
                          className='w-100'
                          size='sm'
                        >
                          <Dropdown.Item onClick={()=>{
                             setTipoFiltroForm(0);
                          }}>
                            Corsa
                          </Dropdown.Item>
                          <Dropdown.Item onClick={()=>{
                             setTipoFiltroForm(1);
                          }}> 
                            Cavallo
                          </Dropdown.Item>
                        </DropdownButton>
                        <Form.Control
                            type="text"
                            id="inputEventDescription"
                            placeholder={(tipo_filtro_form==0? 'Cerca corsa': 'Cerca cavallo')}
                            value={filtro_testo}
                            onChange={(event)=>{
                              setFiltroTesto(event.target.value)
                            }
                            }
                          />
                        </InputGroup>
                        <Button variant="secondary" className="mx-2 bi bi-search" type="submit" title={(tipo_filtro_form==0? 'Cerca corsa': 'Cerca cavallo')}></Button>
                    </Form>
                  <CestinoEventoCompetitor eventi={eventi}/>
                  </ul>
                  <ul className="navbar-nav d-flex flex-row me-1">
                  <Dropdown className='mx-2'>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic" title='Tipi Ordinamento'>
                        {nomeTipoOrdinamentoAttivo()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                        className={(tipo_ordinamento == IppicaConst.TIPO_ORDINAMENTO_PER_DATA ? 'bg-secondary bg-gradient': '')}
 
                        onClick = {()=>{
                            dispatch(IppicaActions.changeTipoOrdinamento(IppicaConst.TIPO_ORDINAMENTO_PER_DATA))
                            setTipoOrdinamento(IppicaConst.TIPO_ORDINAMENTO_PER_DATA)
                          }} 
                        >
                          ORDINAMENTO DATA
                        </Dropdown.Item>

                        <Dropdown.Item
                          className={(tipo_ordinamento == IppicaConst.TIPO_ORDINAMENTO_PER_CAMPIONATO ? 'bg-secondary bg-gradient': '')}
                          onClick = {()=>{
                            dispatch(IppicaActions.changeTipoOrdinamento(IppicaConst.TIPO_ORDINAMENTO_PER_CAMPIONATO))

                            setTipoOrdinamento(IppicaConst.TIPO_ORDINAMENTO_PER_CAMPIONATO)
                          }}
                        >
                          ORDINAMENTO CAMPI
                        </Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className='mx-2'>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic" title='Lista filtri per data'>
                        {nomeFiltroDataAttivo()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                        className={(tipo_filtro_data == IppicaConst.EVENTI_OGGI ? 'bg-secondary bg-gradient': '')}
 
                        onClick = {()=>{
                            dispatch(IppicaActions.setTipoFiltroData(IppicaConst.EVENTI_OGGI))
                          }} 
                        >
                          OGGI
                        </Dropdown.Item>

                        <Dropdown.Item
                          className={(tipo_filtro_data == IppicaConst.EVENTI_OGGI_DOMANI ? 'bg-secondary bg-gradient': '')}
                          onClick = {()=>{
                            dispatch(IppicaActions.setTipoFiltroData(IppicaConst.EVENTI_OGGI_DOMANI))
                          }}
                        >
                          OGGI - DOMANI
                        </Dropdown.Item>

                        <Dropdown.Item
                        className={(tipo_filtro_data == IppicaConst.EVENTI_TUTTI ? 'bg-secondary bg-gradient': '')}
                        onClick = {()=>{
                          dispatch(IppicaActions.setTipoFiltroData(IppicaConst.EVENTI_TUTTI))
                          }}
                        >
                          TUTTI
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='mx-2'>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic" title='Lista tipologie visualizzazione'>
                        {nomeTipologiaVisualizzazione()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item 
                        className={(tipo_visualizzazione == IppicaConst.VISUALIZZAZIONE_1_COLONNA ? 'bg-secondary bg-gradient': '')}
                        onClick = {()=>{
                          setTipoVisualizzaione(IppicaConst.VISUALIZZAZIONE_1_COLONNA)
                          if(list)
                            list.recomputeRowHeights(0)
                          }} 
                        >
                          1 Colonna
                          <i className="mx-1 bi bi-square"></i>
                        </Dropdown.Item>

                        <Dropdown.Item
                        className={(tipo_visualizzazione == IppicaConst.VISUALIZZAZIONE_2_COLONNE ? 'bg-secondary bg-gradient': '')}
                        onClick = {()=>{
                          setTipoVisualizzaione(IppicaConst.VISUALIZZAZIONE_2_COLONNE)
                          if(list)    
                            list.recomputeRowHeights(0)

                          }}
                        >
                          2 Colonne
                          <i className="mx-1 bi bi-layout-split"></i>
                        </Dropdown.Item>

                        <Dropdown.Item 
                        className={(tipo_visualizzazione == IppicaConst.VISUALIZZAZIONE_3_COLONNE ? 'bg-secondary bg-gradient': '')}
                        onClick = {()=>{
                          setTipoVisualizzaione(IppicaConst.VISUALIZZAZIONE_3_COLONNE)
                          if(list)
                            list.recomputeRowHeights(0)

                          }}
                        >
                          3 Colonne
                          <i className="mx-1 bi bi-layout-three-columns"></i>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                      <p className='divisore-icone'></p>

                      <Button 
                          className="bi bi-arrow-clockwise btn btn-light"
                          onClick={()=>{
                            confirmAlert({
                              title: '',
                              message: 'Sicuro di voler ricaricare la pagina?',
                              buttons: [
                                {
                                  label: 'Si',
                                  onClick: () => window.location.reload(false)
                                },
                                {
                                  label: 'No',
                                }
                              ]
                            }
                          );
                        }
                      }
                      title='Ricarica la pagina'
                      ></Button>
                      <p className='divisore-icone'></p>
                      <Button
                        className={'btn btn-light ' + ((eye_state) ? "text-white bi bi-eye-slash bg-secondary" : "bi bi-eye")}
                        onClick = {()=>{
                          if(!eye_state) setFiltroTesto('');
                          dispatch(IppicaActions.setEyeState(!eye_state))
                          }}
                          title={(eye_state? 'Visualizza tutto': 'Visualizza solo Alert')}    
                      >
                        
                      </Button>
                      <p className='divisore-icone'></p>
                      <Button
                        className={'btn btn-light ' + ((visibilita_competitors)? "bi bi-arrows-collapse": "bi bi-arrows-expand")}
                        onClick = {()=>{
                            
                            dispatch(IppicaActions.allSetStateOpenCompetitor(!visibilita_competitors))
                            setVibilitaCompetitors(!visibilita_competitors)
                          }}
                        title={(visibilita_competitors? 'Chiudi tutti i cavalli': 'Apri tutti i cavalli')}
                      >
                      </Button>
                      <Button 
                        className= {'btn btn-light ' + ((visibilita_eventi) ? "bi bi-arrows-angle-contract" : "bi bi-arrows-angle-expand")}
                        style={{float: "right"}}
                        onClick = {()=>{
                            dispatch(IppicaActions.allSetStateOpenEvento(!visibilita_eventi))
                            setVibilitaEventi(!visibilita_eventi)
                          }}
                        title={(visibilita_eventi? 'Chiudi tutti gli eventi': 'Apri tutti gli eventi')}
                      >
                      </Button>
                      <p className='divisore-icone'></p>
                      <Button 
                        className= {'btn btn-light ' + ((alert_sound_state) ? "bi bi-volume-up-fill" : "bi bi-volume-mute-fill")}
                        style={{float: "right"}}
                        onClick = {()=>{
                            dispatch(IppicaActions.changeAlertSoundState())
                          }}
                        title={(alert_sound_state? 'Disabilita il suono': 'Abilita il suono')}    
                      >

                      </Button>
                    
                    

                    <Button title='Impostazioni' variant="primary" onClick={handleShowFiltri} className='bi bi-gear-fill' variant="light">
                    </Button>
                    <Offcanvas show={showFiltri} onHide={handleCloseFiltri} scroll={true} backdrop={true} placement={'end'}>
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title style={{width: '355px', textAlign: 'center'}}>
                          IMPOSTAZIONI
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                      <div className='container'>
                      <div className='box-impostazioni'>
                          <h6 className='title-box-impostazioni'>Avvisi</h6>
                          {Object.keys(alert_abilitati).map((alert)=>
                            <div key={alert} className='row'> 
                              <div  className='col-8'>
                                  <Form.Check
                                    type={'checkbox'}
                                    checked={alert_abilitati[alert]['abilitato']}
                                    onChange={()=>{
                                      var temp_alert = {...alert_abilitati}
                                      temp_alert[alert]['abilitato'] = !temp_alert[alert]['abilitato']
                                      dispatch(IppicaActions.setAlertAbilitati(temp_alert))
                                    }}
                                    style={{marginTop: '0.25rem'}}
                                    key={alert_abilitati[alert]['descrizione']}
                                    name={alert_abilitati[alert]['descrizione']}
                                    label={alert_abilitati[alert]['descrizione']}
                                    value={alert_abilitati[alert]['abilitato']}
                                  >
                                  </Form.Check>
                              </div>
                              <div className='col-4'>
                                {alert && alert === 'BF' &&
                                 <Form.Control
                                    type="number"
                                    id="inputDistance"
                                    size='sm'
                                    step={0.01}
                                    max={1}
                                    min={-1}
                                    disabled={!alert_abilitati[alert]['abilitato']}
                                    onChange={(event)=>{
                                    dispatch(IppicaActions.setDistance(event.target.value))
                                    dispatch(IppicaActions.checkAlertDistance());
                                    }}
                                    value = {distanza_banca}>
                                 </Form.Control>
                                }
                                {alert && alert === 'BF_PUNTA' &&
                                <Form.Control
                                    type="number"
                                    id="inputDistance"
                                    size='sm'
                                    step={0.01}
                                    max={1}
                                    min={-1}
                                    disabled={!alert_abilitati[alert]['abilitato']}
                                    onChange={(event)=>{
                                      dispatch(IppicaActions.setDistancePunta(event.target.value))
                                      dispatch(IppicaActions.checkAlertDistancePunta());
                                    }}
                                    value = {distanza_punta}>
                                 </Form.Control>
                                
                                }
                              </div>
                            </div>

                          )}
                        </div>
                        <div className='box-impostazioni'>
                          <h6 className='title-box-impostazioni'>Concessionari</h6>
                          <div className='row container'>
                            {concessionari && concessionari.map((concessionario)=>{
                                if(concessionario.concessionario_codice != IppicaConst.CONCESSIONARIO_VIRTUALE){
                                    return (
                                        <Form.Check
                                          type={'checkbox'}
                                          checked = {concessionario.visible}
                                          onChange = {()=>{
                                            dispatch(IppicaActions.changeVisualizationConcessionari(concessionario))
                                            }}
                                          key={concessionario.concessionario_codice}
                                          name={concessionario.concessionario_descrizione}
                                          label={concessionario.concessionario_descrizione}
                                          value={concessionario.concessionario_descrizione}
                                          className='col-6'
                                        />
                                    )
                                }
                              }
                            )}
                            </div>
                        </div>
                        <div className='box-impostazioni'>
                          <h6 className='bold title-box-impostazioni'>Specialità</h6>
                          <div className='row'>
                          {specialita && specialita.map((spec)=>
                            <div key={spec.id} className='col-6' >
                              <Form.Check
                                type={'checkbox'}
                                checked={spec.visible}
                                onChange={()=>{
                                  dispatch(IppicaActions.changeVisualizationSpecialita(spec))
                                  if(eye_state){
                                    dispatch(IppicaActions.setEyeState(eye_state))
                                  }
                                  }}
                                key={spec.descrizione}
                                name={spec.descrizione}
                                label={spec.descrizione}
                                value={spec.descrizione}
                              />
                            </div>
                          )}
                          </div>
                        </div>
                        <div className='box-impostazioni'>
                          <div>
                            <h6 className='title-box-impostazioni'>Visualizza</h6>
                          </div>
                          <div className='row'>
                            <div className='col-5' style={{paddingTop: '0.25rem'}}>
                              Corse
                            </div>
                            <div className='col-7'>
                              <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic" title='Visualizza tutte le corse o solo con quote'>
                                  {nomeVisualizzazioneQuotePresentiAttiva()} 
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    className={(visualizza_quote_presenti? 'bg-secondary bg-gradient': '')}
                                    onClick = {()=>{
                                        dispatch(IppicaActions.setVisualizzaQuotePresenti(true))
                                      }} 
                                  >
                                    Tutte
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className={(!visualizza_quote_presenti? 'bg-secondary bg-gradient': '')}
                                    onClick = {()=>{
                                        dispatch(IppicaActions.setVisualizzaQuotePresenti(false))
                                      }} 
                                  >
                                    Solo quotate
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className='my-4 text-center'>
                          <Button
                            onClick={()=>{
                              dispatch(IppicaActions.saveImpostazioni())
                              // permette di chiudere la lista dei filtri
                              handleCloseFiltri()
                            }}>
                              Salva Impostazioni
                          </Button>
                        </div>
                      </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </ul>
              </div>
          </div>
      </nav>
      {campionato_specialita && 
        <Modal centered show={(open_gestisci_specialita)} onHide={() =>{setOpenGestisciSpecialita(false)}}  size='lg' scrollable='true'>
            <Modal.Header   onHide={() =>{setOpenGestisciSpecialita(false)}} closeButton>
                <h4 className="modal-title">Avvisi Specialita - {campionato_specialita.campionato_descrizione}</h4>
            </Modal.Header>
            <Modal.Body>
                <div className='row' >
                  <div className='mb-3'>
                    In questa schermata è possibile disattivare le specialità per cui non ricevere gli avvisi. La specialità continua ad essere visualizzata ma non si riceveranno più gli avvisi per quelle disattivate.
                  </div>
                  {specialita && specialita.map((spec)=>
                    <div key={spec.id} className='col-4'>
                      <Form.Check
                        type="checkbox"
                        checked={campionato_specialita.specialita_attive[spec.id]} onChange={(e)=>{
                          dispatch(IppicaActions.setSpecialitaCampionato(campionato_specialita, spec.id, e.target.checked))
                        }}
                        label = {spec.descrizione}
                      />
                    </div>
                  )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() =>{setOpenGestisciSpecialita(false)}}>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
      }
      {evento_specialita && 
        <Modal centered show={open_gestisci_specialita} onHide={() =>{setOpenGestisciSpecialita(false)}}  size='lg' scrollable='true'>
          <Modal.Header onHide={() =>{setOpenGestisciSpecialita(false)}} closeButton>
              <h4 className="modal-title">Avvisi Specialita - {evento_specialita.descrizione}</h4>
          </Modal.Header>
          <Modal.Body>
              <div className='row' >
                <div className='mb-3'>
                    In questa schermata è possibile disattivare le specialità per cui non ricevere gli avvisi. La specialità continua ad essere visualizzata ma non si riceveranno più gli avvisi per quelle disattivate.
                </div>

                {specialita && specialita.map((spec)=>
                <div key={spec.id} className='col-4'>
                  <Form.Check
                    type="checkbox"
                    checked={evento_specialita.specialita_attive[spec.id]} onChange={(e)=>{
                      dispatch(IppicaActions.setSpecialitaEvento(evento_specialita, spec.id, e.target.checked))
                    }}
                    label = {spec.descrizione}
                  />
                </div>
                )}
              </div>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="light" onClick={() =>{setOpenGestisciSpecialita(false)}}>
                  Chiudi
              </Button>
          </Modal.Footer>
      </Modal>
      
      }
     
      <List
        style={(openCampionati? { marginLeft: "250px"}: {})} 
        ref={bindListRef}
        width= {sizeList[0]-(openCampionati? 250: 0)}
        height={sizeList[1]-58}
        rowCount={eventi.length}
        rowHeight={rowheight}
        rowRenderer={rowRenderer}
        overscanRowCount={2}
      />
      </>: 
      <>
        <div >
          <h2 className='container-empty-page'><i class="bi bi-x-circle-fill"></i> Nessun evento presente</h2>
        </div>
      </>
    }
    
    </div>
    }
    </>);

  function modalGestisciSpecialitaEvento(evento) {
    setCampionatoSpecialita(null)
    setEventoSpecialita(evento)
    setOpenGestisciSpecialita(true)
  }

  function modalGestisciSpecialitaCampionato(campionato) {
    setEventoSpecialita(null)
    setCampionatoSpecialita(campionato)
    setOpenGestisciSpecialita(true)
  }

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
    }) {
    // if(isScrolling) {
    //   return (
    //     <div
    //       key={key}
    //       style={style}>
    //       ...
    //     </div>
    //   );
    // }
    return (
      <div style={style} key={key} className='container-fluid' >
        <RowEvento 
          alert_abilitati = {alert_abilitati} 
          eventoScrollVisible={true} 
          eventiVisibility={visibilita_eventi} 
          marcatoriVisibility={visibilita_competitors} 
          evento={eventi[index]} 
          specialita={specialita} 
          concessionari={concessionari} 
          quote={quote} 
          distanza_banca={distanza_banca}
          distanza_punta={distanza_punta}
          visualizzazione={tipo_visualizzazione}
          attivaGestioneSpecialita = {modalGestisciSpecialitaEvento}
          visualizza_quote_presenti = {visualizza_quote_presenti}
          />
      </div>
      );      
    }
  
  function rowheight({ index }){
    if (!isRowLoaded({index})){
      return 0.8*window.innerWidth;
    }else{      
      return getHeighRowEventoCompetitor(eventi[index], tipo_visualizzazione, concessionari, visualizza_quote_presenti)
    }
  }
  
  function bindListRef (ref) {
      dispatch(IppicaActions.setRefList(ref));
      list = ref
  };


  

  };
  
  export default Ippica;