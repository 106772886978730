export const ServersConst = {
    SERVER_MARCATORI: 'https://betreaderweb.gcsoft.it/php/marcatori',
    END_POINT_GET_SPECIALITA: '/get_specialita.php', 
    END_POINT_GET_EVENTI:'/get_eventi_marcatori.php',
    END_POINT_GET_EVENTO: '/get_evento_marcatori.php',
    END_POINT_GET_CONCESSIONARI:'/get_concessionari.php',
    END_POINT_GET_QUOTE_FOREVENTO:'/get_quote_for_evento.php',
    END_POINT_GET_QUOTA_DESCRIZIONE: '/get_quota_descrizione.php',
    MILLISECOND_WAIT: 60000,
};

export const MarcatoriConst = {
    // filtro data
    EVENTI_OGGI: 0,
    EVENTI_OGGI_DOMANI: 1,
    EVENTI_TUTTI: 2,

    VERSIONE: "2.4.1", // versione dell'applicazione
    // i valori associati ai singoli alert
    VALUE_ALERT_BF: 1,
    VALUE_ALERT_AVG: 2,

    // disposizione dei marcatori 
    VISUALIZZAZIONE_1_COLONNA: 0,
    VISUALIZZAZIONE_2_COLONNE: 1,
    VISUALIZZAZIONE_3_COLONNE: 2,

    TIPO_ORDINAMENTO_PER_DATA: 0,
    TIPO_ORDINAMENTO_PER_CAMPIONATO:1,

    CONCESSIONARIO_VIRTUALE: 'MAX Odds',


    CONCESSIONARIO_BETFAIR: "BETFAIR",        // codice concessionario utilizzato per Betfair

    CONFRONTO: ["BETFAIR"],          // elenco dei concessionari che non verrano utilizzati
                                    // nel calcolo della quota massima, quota media e in generale
                                    // contiene solo i concessionari che servono come confronto


    ALTEZZA_RIGA_EVENTO: 59.5,
    ALTEZZA_RIGA_MARCATORE_1_COLONNA_CHIUSA: 31,
    ALTEZZA_RIGA_MARCATORE_2_3_COLONNE_CHIUSA: 50.5,
    ALTEZZA_NOME_MARCATORE: 31,
    ALTEZZA_INTESTAZIONE_SPECIALITA: 30.65, // aggiunto anche la dimensione della riga sottostante di 1px
}