import React from 'react';
import {Table} from 'react-bootstrap'
import MarcatoreQuotaTabella from './MarcatoreQuotaTabella';
import MarcatoreQuotaTabellaBF from './MarcatoreQuotaTabellaBF';
import { MarcatoriConst } from '../../constants';
import  {useDispatch} from 'react-redux';
import * as MarcatoriActions from '../../redux/marcatori/actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const RowMarcatore = (props) => {
    // const [visible, setVisible] = useState(true)
    const marcatore = props.marcatore
    const evento_id = props.evento_id
    const classColumns = props.classColumns
    
    const dispatch = useDispatch()

    /*useEffect(() => {
      setVisible(props.visibilitaMarcatore)
    }, [props.visibilitaMarcatore]);*/

    const checkExistValue = (evento_id, quota_id, concessionario_codice, quote) =>{
        return quote && evento_id in quote && quota_id in quote[evento_id] && concessionario_codice in quote[evento_id][quota_id]
      }


    const getCellaQuota = (concessionario, valore, valore_2) => {
      if(concessionario == MarcatoriConst.CONCESSIONARIO_BETFAIR){
        return <MarcatoreQuotaTabellaBF quota_2={valore_2} quota={valore}></MarcatoreQuotaTabellaBF>
      }else{
        return <MarcatoreQuotaTabella quota={valore}></MarcatoreQuotaTabella>
      }
    }

    return (
      marcatore.visible && marcatore.enabled &&
        <div className={classColumns + ' card my-1 mx-1'} key = {marcatore.quota_descrizione + evento_id + '1'} >
                  <div key = {marcatore.quota_descrizione + evento_id + '3'} className={'fw-bold fs-marcatore row'}>
                  <div className='col-3 text-nowrap overflow-hidden ps-1'>
                  <span style={{cursor:'pointer'}}><i className="bi bi-x-lg p-1" onClick = {()=>{
                      confirmAlert({
                          title: '',
                          message: 'Sicuro di voler eliminare il marcatore?',
                          buttons: [
                            {
                              label: 'Si',
                              onClick: () => {
                                dispatch(MarcatoriActions.setStateEnabledPlayer(props.evento, marcatore, false))
                              }

                            },
                            {
                              label: 'No',
                            }
                          ]
                        }
                      );
                  }}
                    title='Elimina marcatore'
                  ></i></span>
                  {/* <i className={(marcatore.open)? 'bi bi-arrows-collapse mx-1' : 'bi bi-arrows-expand mx-1'}  onClick = {()=>{
                          dispatch(MarcatoriActions.setStateOpenMarcatore(!marcatore.open, props.evento, marcatore));
                  }}></i> */}
                  <span title={(marcatore.open? 'Chiudi marcatore': 'Apri marcatore')} style={{cursor:'pointer', userSelect:'none'}} onClick = {()=>{
                          dispatch(MarcatoriActions.setStateOpenMarcatore(!marcatore.open, props.evento, marcatore));
                  }}>{marcatore.quota_descrizione}</span>
                  </div>
                  <div className='col-9'>
                  {!marcatore.open && <>
                      <Table className='table-borderless mb-0 w-auto' align={'right'} striped="">
                        <tbody key = {marcatore.quota_descrizione + evento_id + '5'}>
                              <tr>
                                <td width={150} className='align-middle fs-quote text-muted pe-3'  align={'right'}>
                                  <span 
                                    className='bi bi-arrows-expand' 
                                    style={{float:'right',cursor:'pointer', userSelect:'none'}} 
                                    onClick={()=>{
                                    dispatch(MarcatoriActions.setStateOpenMarcatore(!marcatore.open, props.evento, marcatore));
                                  }}
                                    title='Apri marcatore'
                                  ></span>
                                </td>
                                {props.specialita && props.specialita.map((spec)=>
                                  {
                                    var check_exist = checkExistValue(props.evento.evento_id, marcatore.specialita[spec.id],MarcatoriConst.CONCESSIONARIO_VIRTUALE, props.quote);
                                    return (
                                      spec.visible && 
                                      <td 
                                      
                                      key = {MarcatoriConst.CONCESSIONARIO_VIRTUALE + spec.id + evento_id} 
                                      className={'fs-specialita fs-quote text-center' + 
                                                (check_exist 
                                                    && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] & 1
                                                    && props.alert_abilitati['BF']['abilitato']? ' bg-danger text-warning': '') +
                                                (check_exist 
                                                    && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] & 2
                                                    && (!(props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] & 1) || !props.alert_abilitati['BF']['abilitato'])   
                                                    && props.alert_abilitati['AVG']['abilitato']? ' bg-warning text-danger': '')}
                                      onClick={()=>{
                                        if(
                                          check_exist 
                                          && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] > 0
                                          && ((props.alert_abilitati['BF']['abilitato'] && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] & 1) 
                                            || (props.alert_abilitati['AVG']['abilitato'] && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] & 2)
                                            ) 
                                          ){
                                          dispatch(MarcatoriActions.setStateOpenMarcatore(!marcatore.open, props.evento, marcatore));
                                        }
                                      }}
                                      style={(
                                        check_exist 
                                        && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] > 0 
                                        && ((props.alert_abilitati['BF']['abilitato'] && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] & 1) 
                                            || (props.alert_abilitati['AVG']['abilitato'] && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['alert'] & 2)
                                            ) 
                                        ? {cursor:"pointer"}: {})}
                                      width={70}>
                                      {check_exist  
                                        && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['value']>0 ?
                                        <MarcatoreQuotaTabella quota={props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][MarcatoriConst.CONCESSIONARIO_VIRTUALE]['value']}></MarcatoreQuotaTabella>
                                        : '-'
                                      }
                                      </td>

                                    );   
                                  }
                                  
                                )}
                              </tr>
                          </tbody>
                      </Table>
                      </> }
                      {marcatore.open && <>
                      <Table className='table-borderless mb-0 w-auto' align={'right'} striped="columns">
                      
                        <tbody key = {marcatore.quota_descrizione + evento_id + '5'}>
                        <tr style={{borderBottom:"1px solid #ccc"}}>
                          <td width={90} style={{borderRight:"1px solid #ccc"}} className='text-center fs-quote text-muted pe-3'>
                            <span 
                              className='bi bi-arrows-collapse'
                              style={{float:'right',cursor:'pointer', userSelect:'none'}}
                              onClick={()=>{
                                    dispatch(MarcatoriActions.setStateOpenMarcatore(!marcatore.open, props.evento, marcatore));
                                  }}
                              title='Chiudi marcatore'
                              ></span>
                          </td>
                          {props.specialita && props.specialita.map((spec)=>
                            spec.visible && <td key={spec.id + evento_id} className='text-center fw-normal text-muted align-middle fs-quote ' width={70} >{spec.descrizione}</td>
                            )}
                        </tr>
                        
                        
                          {props.concessionari && props.concessionari.map((concessionario)=>
                            concessionario.visible && <tr  key={concessionario.concessionario_codice + evento_id + '1'}>
                              <td  key={concessionario.concessionario_codice + evento_id + '2'} width={90} style={{borderRight:"1px solid #ccc"}} className='align-middle fw-normal fs-concessionario text-muted pe-3'  align={'right'}> {concessionario.concessionario_descrizione}</td>
                              {props.specialita && props.specialita.map((spec)=>{
                                var check_exist = checkExistValue(props.evento.evento_id, marcatore.specialita[spec.id], concessionario.concessionario_codice, props.quote)
                                return (
                                  spec.visible && <td 
                                  title={(check_exist?  props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['quota_descrizione']: '')}  
                                key = {concessionario.concessionario_codice + spec.id + evento_id} 
                                className={'fs-specialita fs-quote text-center' + 
                                          (check_exist 
                                              && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] & 1
                                              && props.alert_abilitati['BF']['abilitato']? ' bg-danger text-warning': '') + 
                                          (check_exist
                                              && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] & 2
                                              && (!(props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] & 1) || !props.alert_abilitati['BF']['abilitato']) 
                                              && props.alert_abilitati['AVG']['abilitato']? ' bg-warning text-danger': '')} 
                                onClick={()=>{
                                  if(
                                    check_exist 
                                    && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] > 0
                                    && ((props.alert_abilitati['BF']['abilitato'] && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] & 1) 
                                            || (props.alert_abilitati['AVG']['abilitato'] && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] & 2)
                                            ) 
                                    ){
                                    dispatch(MarcatoriActions.setStateOpenMarcatore(!marcatore.open, props.evento, marcatore));
                                    } 
                                }}
                                style={(
                                  check_exist 
                                  && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] > 0
                                  && ((props.alert_abilitati['BF']['abilitato'] && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] & 1) 
                                            || (props.alert_abilitati['AVG']['abilitato'] && props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['alert'] & 2)
                                            ) 
                                  ? {cursor:"pointer"}: {})}

                                width={70}>
                                {check_exist ?
                                  getCellaQuota(concessionario.concessionario_codice, props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['value'], props.quote[props.evento.evento_id][marcatore.specialita[spec.id]][concessionario.concessionario_codice]['value_2'])
                                  : ''}
                                </td>
                                );
                              }  
                              )}
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      </> }
                      </div>
                  </div>
        </div>
    );
}

export default RowMarcatore;