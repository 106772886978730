import React from 'react';



const CompetitorQuotaTabella = (props) => {
    const { quota } = props;
    const alert_class = props.alert_class;

    
    return (
        <>  
            <div className={"position-relative "}>
            {alert_class && alert_class!= '' &&
                <i className={"position-fixed bi bi-circle-fill" + alert_class} style={{fontSize: "6px", margin: "4px 0 0 -10px"}}></i>
            }
                <span className=''>
                {quota > 1 ? parseFloat(quota).toFixed(2) : '-'}
                </span>
            </div>
        </>
    );
}

export default CompetitorQuotaTabella;